.platformLoadingDomWrapper {
    width: 60px;
    background: #131313;
    height: 34px;
    border-radius: 30px;
    position: relative;
    z-index: 1;
    border: 1px solid #808080;
    .circle {
        width: 8px;
        height: 8px;
        position: absolute;
        border-radius: 50%;
        background-color: #fff;
        left: 10px;
        animation: circleJump 0.45s alternate infinite ease;
    }

    .circle:nth-child(2) {
        left: 25px;
        animation-delay: 0.15s;
    }

    .circle:nth-child(3) {
        left: auto;
        right: 10px;
        animation-delay: 0.3s;
    }

    @keyframes circleJump {
        0% {
            top: 6px;
        }

        100% {
            top: 16px;
        }
    }
}
