@import './_fonts.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

// 滚动条整体 整体如果不设置，只s设置滑块和轨道是没有效果的
::-webkit-scrollbar {
    width: 5px;
    height: 5px; // height对于纵向滚动条来说没有用，但是对于横向就有用了
    border-radius: 10px;
}

// 滑块
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #2e3033;
    // height: 50px; // 整体设了宽高后，这里宽高是无效的
    // width: 50px;
}

// 轨道
::-webkit-scrollbar-track {
    border-radius: 10px;
}

::-moz-selection {
    background: #4ee075;
}
::selection {
    background: #4ee075;
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    & > ::-webkit-scrollbar {
        display: none;
    }
}
#root {
    width: 100%;
    height: 100%;
}
.App {
    width: 100%;
    height: 100%;
}
