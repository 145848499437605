/* fonts */
@font-face {
    font-family: 'Inter-Regular';
    src: url('https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/fonts/Inter-Regular.ttf');
}
@font-face {
    font-family: 'Inter';
    src: url('https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/fonts/Inter-Regular.ttf');
}
@font-face {
    font-family: 'Inter-Black';
    src: url('https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/fonts/Inter-Black.ttf');
}
@font-face {
    font-family: 'Inter-Bold';
    src: url('https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/fonts/Inter-Bold.ttf');
}
@font-face {
    font-family: 'Inter-Light';
    src: url('https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/fonts/Inter-Light.ttf');
}
@font-face {
    font-family: 'Inter-Medium';
    src: url('https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/fonts/Inter-Medium.ttf');
}
@font-face {
    font-family: 'Inter-SemiBold';
    src: url('https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/fonts/1670321434936_Inter-SemiBold.ttf');
}
@font-face {
    font-family: 'Inter-ExtraBold';
    src: url('https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/fonts/Inter-ExtraBold.ttf');
}
@font-face {
    font-family: 'DINPro-Bold';
    src: url('https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/fonts/DINPro-Bold.otf');
}
@font-face {
    font-family: 'DINPro-Light';
    src: url('https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/fonts/DINPro-Light.otf');
}
@font-face {
    font-family: 'DINPro-Medium';
    src: url('https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/fonts/DINPro-Medium.otf');
}
@font-face {
    font-family: 'Montserrat-Bold';
    src: url('https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/fonts/1666780422497_Montserrat-Bold.ttf');
}
@font-face {
    font-family: 'Montserrat-Regular';
    src: url('https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/fonts/1666780422503_Montserrat-Regular.ttf');
}
@font-face {
    font-family: 'Montserrat-ExtraBold';
    src: url('https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/fonts/1666781219272_Montserrat-ExtraBold.ttf');
}
@font-face {
    font-family: 'Montserrat-Light';
    src: url('https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/fonts/1666835748480_Montserrat-Light.ttf');
}
@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/fonts/1667216777336_Montserrat-SemiBold.ttf');
}
@font-face {
    font-family: 'Montserrat-Regular';
    src: url('https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/fonts/1667229124677_Montserrat-Regular.ttf');
}

/* vietnamese */
@font-face {
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/darkergrotesque/v7/U9MA6cuh-mLQlC4BKCtayOfARkSVoxbyMWcrTckUoXzH6A.woff2')
        format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
        U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/darkergrotesque/v7/U9MA6cuh-mLQlC4BKCtayOfARkSVoxbyMWYrTckUoXzH6A.woff2')
        format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
        U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('https://fonts.gstatic.com/s/darkergrotesque/v7/U9MA6cuh-mLQlC4BKCtayOfARkSVoxbyMWgrTckUoXw.woff2')
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
