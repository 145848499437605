.text-content-wrap {
    width: 100%;
    background: var(--anthracite, #131313);
    .text-content-wrap-desc {
        width: 1096px;
        height: 359px;
        margin: auto;
        flex-shrink: 0;
        color: #c9c7c7;
        text-align: center;
        font-family: 'Inter-Light';
        font-size: 36px;
        line-height: 50px;
        letter-spacing: -0.72px;
        margin-bottom: 62px;
        margin-top: 127px;
        @media (min-width: 1024px) and (max-width: 1439px) {
            width: 798px;
            height: 359px;
            font-size: 30px;
            letter-spacing: -0.6px;
            margin-bottom: 47px;
            margin-top: 108px;
        }
        @media (max-width: 1023px) {
            width: 100%;
            height: 100%;
            font-size: 26px;
            padding: 0 20px;
            margin-top: 65px;
            margin-bottom: 33px;
            line-height: 38px;
            letter-spacing: -0.52px;
        }
    }
    .text-content-wrap-animations {
        width: 100%;
        height: 63px;
        font-size: 20px;
        color: #131313;
        background: #bfff00;
        white-space: nowrap;
        overflow: hidden;
        .text-content-wrap-animations-box {
            font-family: 'Inter-Black';
            display: inline-block;
            animation: 5s marquee_rtl linear infinite normal;
            height: 63px;
            line-height: 63px;
            text-transform: uppercase;
            .text-content-point {
                margin: 0 20px;
            }
        }

        @keyframes marquee_rtl {
            0% {
                transform: translateX(0px);
                -webkit-transform: translateX(0px);
            }

            100% {
                transform: translateX(-100%);
                -webkit-transform: translateX(-100%);
            }
        }
    }
}
