@media (min-width: 1440px) {
    .blockchainWrap {
        // background-image: url("@/assets/HomePage/BlockchainWrap/headerBg.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        width: 100%;
        height: 100vh;
        position: relative;
        .videoBg {
            width: 100%;
            height: 100vh;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            video {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        .headerLogoContent {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            .logoContent {
                z-index: 2;
                img {
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                }
            }
        }
        .textContentInfo {
            position: absolute;
            left: 50%;
            bottom: 60px;
            transform: translateX(-50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 1;
            .whiteText,
            .themeColorText {
                font-family: 'Inter-Bold';
                font-weight: 700;
                font-size: 95px;
                line-height: 100px;
                text-align: center;
                letter-spacing: -0.02em;
            }
            .whiteText {
                color: #ffffff;
            }
            .themeColorText {
                color: #bfff00;
            }
            p {
                text-align: center;
                width: 426px;
                margin-top: 20px;
                font-family: 'Inter-Light';
                font-weight: 300;
                font-size: 20px;
                line-height: 35px;
                color: #ffffff;
            }
            .lineContent {
                margin: 65px 0;
                width: 1px;
                height: 125px;
                background: #bfff00;
            }
            .scrollText {
                display: flex;
                align-items: center;
                cursor: pointer;
                height: 47px;
                background: #bfff00;
                font-family: 'Inter-SemiBold';
                font-size: 18px;
                &.top {
                    border-bottom: 1px solid #131313;
                }
                transition: background 0.5s linear;
                > div {
                    width: 296px;
                }
                .scrollText-left {
                    color: #131313;
                    height: 47px;
                    line-height: 47px;
                    text-align: center;
                }
                .scrollText-middle {
                    text-align: center;
                    padding: 0 24px;
                    height: 47px;
                    line-height: 47px;
                    border-left: 1px solid #131313;
                    border-right: 1px solid #131313;
                }
                .scrollText-right {
                    flex: 1;
                    color: #131313;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        display: block;
                        cursor: pointer;
                        width: 22px;
                        height: 22px;
                    }
                    .scrollText-right-pc {
                        display: block;
                    }
                    .scrollText-right-phone {
                        display: none;
                    }
                }
            }
            .scrollText:hover {
                background: #131313;
                color: #bfff00;
                .scrollText-left {
                    color: #bfff00;
                }
                .scrollText-middle {
                    border-left: 1px solid #bfff00;
                    border-right: 1px solid #bfff00;
                }
                .scrollText-right {
                    .scrollText-right-pc {
                        display: none;
                    }
                    .scrollText-right-phone {
                        display: block;
                    }
                }
            }
            // .scrollText {
            //   display: flex;
            //   align-items: center;
            //   cursor: pointer;
            //   .arrowBottomContent {
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     width: 26px;
            //     height: 26px;
            //     border: #bfff00 1px solid;
            //     border-radius: 50%;
            //     margin-right: 20px;
            //     overflow: hidden;
            //     img {
            //       animation: arrowBottom 1s linear infinite;
            //     }
            //     @keyframes arrowBottom {
            //       from {
            //         transform: translate(-1px, 0);
            //       }
            //       to {
            //         transform: translate(-1px, 26px);
            //       }
            //     }
            //   }
            //   span {
            //     font-family: "Inter-Light";
            //     color: #fff;
            //     text-align: center;
            //     font-size: 16px;
            //     font-weight: 300;
            //     line-height: 30px;
            //   }
            // }
        }
        .blockchainWrap-twitter {
            position: absolute;
            right: 0;
            top: 0;
            width: 36px;
            height: 36px;
            flex-shrink: 0;
            cursor: pointer;
            z-index: 9999;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
@media (min-width: 1024px) and (max-width: 1439px) {
    .blockchainWrap {
        // background-image: url("@/assets/HomePage/BlockchainWrap/headerBg.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        width: 100vw;
        height: 100vh;
        position: relative;
        .videoBg {
            width: 100%;
            height: 100vh;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            video {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        .headerLogoContent {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            .logoContent {
                z-index: 2;
                img {
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                }
            }
        }
        .textContentInfo {
            z-index: 1;
            position: absolute;
            left: 50%;
            bottom: 50px;
            transform: translateX(-50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            .whiteText,
            .themeColorText {
                font-family: 'Inter-Bold';
                color: #fff;
                text-align: center;
                font-size: 80px;
                font-weight: 700;
                line-height: 85px;
                letter-spacing: -1.6px;
            }
            .whiteText {
                color: #ffffff;
            }
            .themeColorText {
                color: #bfff00;
            }
            p {
                text-align: center;
                width: 396px;
                margin-top: 20px;
                font-family: 'Inter-Light';
                color: #fff;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 300;
                line-height: 35px;
            }
            .lineContent {
                margin: 40px 0;
                width: 1px;
                height: 78px;
                background: #bfff00;
            }
            .scrollText {
                &.top {
                    border-bottom: 1px solid #131313;
                }
                display: flex;
                align-items: center;
                cursor: pointer;
                height: 31px;
                background: #bfff00;
                font-family: 'Inter-SemiBold';
                font-size: 12px;
                -moz-transition: all 0.3s linear;
                > div {
                    width: 194px;
                }
                .scrollText-left {
                    color: #131313;
                    height: 31px;
                    padding: 0 10px;
                    line-height: 31px;
                    text-align: center;
                }

                .scrollText-middle {
                    text-align: center;
                    padding: 0 10px;
                    height: 31px;
                    line-height: 31px;
                    border-left: 1px solid #131313;
                    border-right: 1px solid #131313;
                }

                .scrollText-right {
                    flex: 1;
                    color: #131313;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        display: block;
                        cursor: pointer;
                        width: 15px;
                        height: 15px;
                    }
                    .scrollText-right-pc {
                        display: block;
                    }

                    .scrollText-right-phone {
                        display: none;
                    }
                }
            }
            .scrollText:hover {
                // transition: #131313 0.3s linear;
                background: #131313;
                color: #bfff00;

                .scrollText-left {
                    color: #bfff00;
                }

                .scrollText-middle {
                    border-left: 1px solid #bfff00;
                    border-right: 1px solid #bfff00;
                }

                .scrollText-right {
                    .scrollText-right-pc {
                        display: none;
                    }

                    .scrollText-right-phone {
                        display: block;
                    }
                }
            }
            // .scrollText {
            //   // position: absolute;
            //   // bottom: 58px;
            //   // left: 50%;
            //   // transform: translateX(-50%);
            //   display: flex;
            //   align-items: center;
            //   cursor: pointer;
            //   .arrowBottomContent {
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     width: 26px;
            //     height: 26px;
            //     border: #bfff00 1px solid;
            //     border-radius: 50%;
            //     margin-right: 20px;
            //     overflow: hidden;
            //     img {
            //       animation: arrowBottom 1s linear infinite;
            //     }
            //     @keyframes arrowBottom {
            //       from {
            //         transform: translate(-1px, 0);
            //       }
            //       to {
            //         transform: translate(-1px, 26px);
            //       }
            //     }
            //   }
            //   span {
            //     font-family: "Inter-Light";
            //     color: #fff;
            //     text-align: center;
            //     font-size: 16px;
            //     font-style: normal;
            //     font-weight: 300;
            //     line-height: 30px;
            //   }
            // }
        }
        .blockchainWrap-twitter {
            position: absolute;
            right: 0;
            top: 0;
            width: 36px;
            height: 36px;
            flex-shrink: 0;
            cursor: pointer;
            z-index: 9999;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
@media (max-width: 1023px) {
    .blockchainWrap {
        // background-image: url("@/assets/HomePage/BlockchainWrap/headerBg.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        width: 100%;
        height: 100vh;
        position: relative;
        overflow: hidden;
        .videoBg {
            width: 100%;
            height: 100vh;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            video {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        .headerLogoContent {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            .logoContent {
                z-index: 2;
                img {
                    width: 148px;
                    height: 52px;
                    z-index: 2;
                }
            }
        }
        .textContentInfo {
            z-index: 1;
            position: absolute;
            left: 50%;
            top: 57%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            .whiteText,
            .themeColorText {
                text-align: center;
                font-size: 65px;
                font-family: 'Inter-Bold';
                font-weight: 700;
                line-height: 65px;
                letter-spacing: -1.3px;
            }
            .whiteText {
                color: #ffffff;
            }
            .themeColorText {
                color: #bfff00;
            }
            p {
                font-family: 'Inter-Light';
                margin-top: 30px;
                width: 350px;
                color: #fff;
                text-align: center;
                font-size: 20px;
                font-weight: 300;
                line-height: 35px;
            }
            .lineContent {
                margin: 40px 0;
                width: 1px;
                height: 56px;
                background: #bfff00;
            }
            .scrollText {
                &.top {
                    margin-bottom: 16px;
                }
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                cursor: pointer;
                width: 246px;
                background: #bfff00;
                font-family: 'Inter-SemiBold';
                font-size: 12px;
                border: 1px solid #131313;
                .scrollText-left {
                    width: 246px;
                    color: #131313;
                    height: 35px;
                    line-height: 35px;
                    text-align: center;
                    border-bottom: 1px solid #131313;
                }

                .scrollText-middle {
                    width: 209px;
                    text-align: center;
                    height: 35px;
                    line-height: 35px;
                    border-right: 1px solid #131313;
                }

                .scrollText-right {
                    width: 35px;
                    height: 35px;
                    color: #131313;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        display: block;
                        cursor: pointer;
                        width: 15px;
                        height: 15px;
                    }
                    .scrollText-right-pc {
                        display: block;
                    }

                    .scrollText-right-phone {
                        display: none;
                    }
                }
            }
            .scrollText:hover {
                background: #131313;
                color: #bfff00;
                border: 1px solid #bfff00;
                .scrollText-left {
                    color: #bfff00;
                    border-bottom: 1px solid #bfff00;
                }
                .scrollText-middle {
                    // border-left: 1px solid #BFFF00;
                    border-right: 1px solid #bfff00;
                }
                .scrollText-right {
                    .scrollText-right-pc {
                        display: none;
                    }
                    .scrollText-right-phone {
                        display: block;
                    }
                }
            }
            // .scrollText {
            //   display: flex;
            //   align-items: center;
            //   cursor: pointer;
            //   .arrowBottomContent {
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     width: 18px;
            //     height: 18px;
            //     border: #bfff00 1px solid;
            //     border-radius: 50%;
            //     margin-right: 10px;
            //     overflow: hidden;
            //     img {
            //       animation: arrowBottom 1s linear infinite;
            //     }
            //     @keyframes arrowBottom {
            //       from {
            //         transform: translate(-1px, 0);
            //       }
            //       to {
            //         transform: translate(-1px, 26px);
            //       }
            //     }
            //   }
            //   span {
            //     font-family: "Inter-Light";
            //     color: #fff;
            //     text-align: center;
            //     font-size: 16px;
            //     font-weight: 300;
            //     line-height: 30px;
            //   }
            // }
        }
        .blockchainWrap-twitter {
            position: absolute;
            right: 0;
            top: 0;
            width: 36px;
            height: 36px;
            flex-shrink: 0;
            cursor: pointer;
            z-index: 9999;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
