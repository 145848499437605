.sponsor-wrap {
    width: 100%;
    background: #000;
    .sponsor-header {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        .sponsor-header-logo {
            display: block;
            width: 195px;
            height: 68px;
            margin-right: 31px;
        }

        .sponsor-header-link {
            display: flex;
            align-items: center;
            font-family: 'Inter-Regular';
            font-size: 18px;
            color: #c9c7c7;
            img {
                display: block;
                width: 16px;
                height: 16px;
                cursor: pointer;
                object-fit: cover;
                margin-left: 7px;
            }
        }
    }
    .sponsor-content {
        width: 1044px;
        margin: auto;
        padding: 77px 31px 68px 34px;
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        .sponsor-content-left {
            width: 370px;
            margin-right: 51px;
            .sponsor-content-left-top {
                width: 100%;
                text-align: left;
                font-family: 'Inter-Bold';
                font-size: 42px;
                line-height: 50px;
                color: #fff;
                span {
                    color: #bfff00;
                }
            }
            .sponsor-content-left-bottom {
                margin-top: 40px;
                color: #c9c7c7;
                font-family: 'Inter-Regular';
                font-size: 16px;
                line-height: 35px;
                /* 218.75% */
                letter-spacing: -0.32px;
            }
        }
        .sponsor-login {
            flex: 1;
        }
    }
}

@media (max-width: 1023px) {
    .sponsor-wrap {
        width: 100%;
        background: #000;

        .sponsor-header {
            margin-bottom: 40px;
            display: flex;
            align-items: center;

            .sponsor-header-logo {
                display: block;
                width: 148px;
                height: 51px;
            }

            .sponsor-header-link {
                display: flex;
                margin-left: auto;
                align-items: center;
                font-family: 'Inter-Regular';
                font-size: 14px;
                color: #c9c7c7;
                margin-right: 20px;
                img {
                    display: block;
                    width: 16px;
                    height: 16px;
                    cursor: pointer;
                    object-fit: cover;
                    margin-right: 7px;
                }
            }
        }

        .sponsor-content {
            width: 100%;
            margin: auto;
            padding: 40px 20px 0 20px;
            display: flex;
            // justify-content: space-between;
            flex-wrap: wrap;

            .sponsor-content-left {
                width: 370px;
                margin-right: 51px;

                .sponsor-content-left-top {
                    width: 100%;
                    text-align: left;
                    font-family: 'Inter-Bold';
                    font-size: 42px;
                    line-height: 50px;
                    color: #fff;

                    span {
                        color: #bfff00;
                    }
                }

                .sponsor-content-left-bottom {
                    margin-top: 40px;
                    color: #c9c7c7;
                    font-family: 'Inter-Regular';
                    font-size: 16px;
                    line-height: 35px;
                    /* 218.75% */
                    letter-spacing: -0.32px;
                }
            }

            .sponsor-login {
                width: 100%;
                margin-top: 57px;
            }
        }
    }
}
