@media (min-width: 1440px) {
    .exploreOurOfferWrap {
        width: 1440px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .comTitle {
            width: 1270px;
            margin-top: 120px;
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;
            .whiteText,
            .themeColorText {
                font-size: 65px;
                font-family: 'Inter-Bold';
                font-weight: 700;
                line-height: 65px;
                letter-spacing: -1.3px;
            }
            .whiteText {
                color: #fff;
            }
            .themeColorText {
                color: #bfff00;
            }
        }
        .infoContent {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 1270px;
            .hintContent {
                display: flex;
                flex-direction: column;
                .titleText {
                    color: #fff;
                    font-size: 18px;
                    font-family: 'Inter-SemiBold';
                    font-weight: 600;
                    line-height: 35px;
                    letter-spacing: -0.36px;
                    margin-bottom: 40px;
                }
                .descItem {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    img {
                        width: 28px;
                        height: 28px;
                        margin-right: 26px;
                        margin-top: 6px;
                    }
                    span {
                        width: 485px;
                        color: #fff;
                        font-size: 18px;
                        font-family: 'Inter-Light';
                        font-weight: 300;
                        line-height: 40px;
                    }
                }
            }
            .formContent {
                width: 538px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .titleText {
                    color: #fff;
                    font-size: 18px;
                    font-family: 'Inter-SemiBold';
                    font-weight: 600;
                    line-height: 35px;
                    letter-spacing: -0.36px;
                    margin-bottom: 20px;
                }
                .exploreOurOfferWrap-login {
                    width: 100%;
                }
                // input {
                //   color: #fff;
                //   font-size: 18px;
                //   font-family: "Inter-SemiBold";
                //   font-weight: 600;
                //   line-height: 35px;
                //   letter-spacing: -0.36px;
                // }
                // ::-webkit-input-placeholder {
                //   /*Webkit browsers*/
                //   color: #8a8a8a;
                //   /* Form_placeholder */
                //   font-size: 18px;
                //   font-family: "Inter-SemiBold";
                //   font-weight: 600;
                //   line-height: 35px;
                //   letter-spacing: -0.36px;
                // }
                // :-moz-placeholder {
                //   color: #8a8a8a;
                //   /* Form_placeholder */
                //   font-size: 18px;
                //   font-family: "Inter-SemiBold";
                //   font-weight: 600;
                //   line-height: 35px;
                //   letter-spacing: -0.36px;
                // }
                // ::moz-placeholder {
                //   color: #8a8a8a;
                //   /* Form_placeholder */
                //   font-size: 18px;
                //   font-family: "Inter-SemiBold";
                //   font-weight: 600;
                //   line-height: 35px;
                //   letter-spacing: -0.36px;
                // }
                // :-ms-input-placeholder {
                //   color: #8a8a8a;
                //   /* Form_placeholder */
                //   font-size: 18px;
                //   font-family: "Inter-SemiBold";
                //   font-weight: 600;
                //   line-height: 35px;
                //   letter-spacing: -0.36px;
                // }
                // /* Input container */
                // .input-container {
                //   position: relative;
                //   width: 100%;
                //   height: 52px;
                //   margin-bottom: 28px;
                // }
                // /* Input field */
                // .input-field {
                //   display: block;
                //   width: 100%;
                //   font-size: 16px;
                //   border: none;
                //   border-bottom: 1px solid #ccc;
                //   outline: none;
                //   background-color: transparent;
                //   height: 52px;
                // }
                // /* Input label */
                // .input-label {
                //   position: absolute;
                //   top: 0;
                //   left: 0;
                //   font-size: 16px;
                //   color: rgba(204, 204, 204, 0);
                //   pointer-events: none;
                //   transition: all 0.3s ease;
                // }
                // /* Input highlight */
                // .input-highlight {
                //   position: absolute;
                //   bottom: 0;
                //   left: 0;
                //   height: 1px;
                //   width: 0;
                //   background-color: #bfff00;
                //   transition: all 0.3s ease;
                // }
                // /* Input field:focus styles */
                // .input-field:focus + .input-label {
                //   top: -20px;
                //   color: #8a8a8a;
                //   font-size: 14px;
                //   font-family: "Inter-SemiBold";
                //   font-weight: 600;
                //   letter-spacing: -0.28px;
                // }
                // .input-field:focus + .input-label + .input-highlight {
                //   width: 100%;
                // }
                // .joinContent {
                //   margin-top: 38px;
                //   width: 100%;
                //   display: flex;
                //   flex-direction: row;
                //   justify-content: flex-end;
                //   .joinBtn {
                //     width: 280px;
                //     height: 54px;
                //     background: #bfff00;
                //     display: flex;
                //     align-items: center;
                //     cursor: pointer;
                //     border: none;
                //     .btnText {
                //       background: #000;
                //       width: 224px;
                //       height: 50px;
                //       display: flex;
                //       justify-content: center;
                //       align-items: center;
                //       margin-left: 2px;
                //       transition-duration: 0.42s;
                //       span {
                //         color: #bfff00;
                //         font-size: 13px;
                //         font-family: "Inter-Bold";
                //         font-weight: 700;
                //         line-height: 14px;
                //         letter-spacing: 3.25px;
                //         text-transform: uppercase;
                //       }
                //     }
                //     .arrowImg {
                //       flex: 1;
                //       display: flex;
                //       justify-content: center;
                //       align-items: center;
                //     }
                //   }
                //   .joinBtn:hover {
                //     .btnText {
                //       background: #373737;
                //     }
                //   }
                // }
            }
        }
    }
}
@media (min-width: 1024px) and (max-width: 1439px) {
    .exploreOurOfferWrap {
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        .comTitle {
            width: 976px;
            margin-top: 120px;
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;
            .whiteText,
            .themeColorText {
                font-size: 42px;
                font-family: 'Inter-Bold';
                font-weight: 700;
                line-height: 50px;
                letter-spacing: -1.3px;
            }
            .whiteText {
                color: #fff;
            }
            .themeColorText {
                color: #bfff00;
            }
        }
        .infoContent {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 976px;
            .hintContent {
                display: flex;
                flex-direction: column;
                .titleText {
                    color: #fff;
                    font-size: 18px;
                    font-family: 'Inter-SemiBold';
                    font-weight: 600;
                    line-height: 35px;
                    letter-spacing: -0.36px;
                    margin-bottom: 40px;
                }
                .descItem {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    img {
                        width: 28px;
                        height: 28px;
                        margin-right: 26px;
                        margin-top: 6px;
                    }
                    span {
                        width: 485px;
                        color: #fff;
                        font-size: 18px;
                        font-family: 'Inter-Light';
                        font-weight: 300;
                        line-height: 40px;
                    }
                }
            }
            .formContent {
                width: 538px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .titleText {
                    color: #fff;
                    font-size: 18px;
                    font-family: 'Inter-SemiBold';
                    font-weight: 600;
                    line-height: 35px;
                    letter-spacing: -0.36px;
                    margin-bottom: 20px;
                }
                .exploreOurOfferWrap-login {
                    width: 100%;
                }
                // input {
                //   color: #fff;
                //   font-size: 18px;
                //   font-family: "Inter-SemiBold";
                //   font-weight: 600;
                //   line-height: 35px;
                //   letter-spacing: -0.36px;
                // }
                // ::-webkit-input-placeholder {
                //   /*Webkit browsers*/
                //   color: #8a8a8a;
                //   /* Form_placeholder */
                //   font-size: 18px;
                //   font-family: "Inter-SemiBold";
                //   font-weight: 600;
                //   line-height: 35px;
                //   letter-spacing: -0.36px;
                // }
                // :-moz-placeholder {
                //   color: #8a8a8a;
                //   /* Form_placeholder */
                //   font-size: 18px;
                //   font-family: "Inter-SemiBold";
                //   font-weight: 600;
                //   line-height: 35px;
                //   letter-spacing: -0.36px;
                // }
                // ::moz-placeholder {
                //   color: #8a8a8a;
                //   /* Form_placeholder */
                //   font-size: 18px;
                //   font-family: "Inter-SemiBold";
                //   font-weight: 600;
                //   line-height: 35px;
                //   letter-spacing: -0.36px;
                // }
                // :-ms-input-placeholder {
                //   color: #8a8a8a;
                //   /* Form_placeholder */
                //   font-size: 18px;
                //   font-family: "Inter-SemiBold";
                //   font-weight: 600;
                //   line-height: 35px;
                //   letter-spacing: -0.36px;
                // }
                // /* Input container */
                // .input-container {
                //   position: relative;
                //   width: 100%;
                //   height: 52px;
                //   margin-bottom: 28px;
                // }
                // /* Input field */
                // .input-field {
                //   display: block;
                //   width: 100%;
                //   font-size: 16px;
                //   border: none;
                //   border-bottom: 1px solid #ccc;
                //   outline: none;
                //   background-color: transparent;
                //   height: 52px;
                // }
                // /* Input label */
                // .input-label {
                //   position: absolute;
                //   top: 0;
                //   left: 0;
                //   font-size: 16px;
                //   color: rgba(204, 204, 204, 0);
                //   pointer-events: none;
                //   transition: all 0.3s ease;
                // }
                // /* Input highlight */
                // .input-highlight {
                //   position: absolute;
                //   bottom: 0;
                //   left: 0;
                //   height: 1px;
                //   width: 0;
                //   background-color: #bfff00;
                //   transition: all 0.3s ease;
                // }
                // /* Input field:focus styles */
                // .input-field:focus + .input-label {
                //   top: -20px;
                //   color: #8a8a8a;
                //   font-size: 14px;
                //   font-family: "Inter-SemiBold";
                //   font-weight: 600;
                //   letter-spacing: -0.28px;
                // }
                // .input-field:focus + .input-label + .input-highlight {
                //   width: 100%;
                // }
                // .joinContent {
                //   margin-top: 38px;
                //   width: 100%;
                //   display: flex;
                //   flex-direction: row;
                //   justify-content: flex-end;
                //   .joinBtn {
                //     width: 280px;
                //     height: 54px;
                //     background: #bfff00;
                //     display: flex;
                //     align-items: center;
                //     cursor: pointer;
                //     border: none;
                //     .btnText {
                //       background: #000;
                //       width: 224px;
                //       height: 50px;
                //       display: flex;
                //       justify-content: center;
                //       align-items: center;
                //       margin-left: 2px;
                //       transition-duration: 0.42s;
                //       span {
                //         color: #bfff00;
                //         font-size: 13px;
                //         font-family: "Inter-Bold";
                //         font-weight: 700;
                //         line-height: 14px;
                //         letter-spacing: 3.25px;
                //         text-transform: uppercase;
                //       }
                //     }
                //     .arrowImg {
                //       flex: 1;
                //       display: flex;
                //       justify-content: center;
                //       align-items: center;
                //     }
                //   }
                //   .joinBtn:hover {
                //     .btnText {
                //       background: #373737;
                //     }
                //   }
                // }
            }
        }
    }
}
@media (max-width: 1023px) {
    .exploreOurOfferWrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .comTitle {
            width: 350px;
            margin-top: 60px;
            margin-bottom: 40px;
            display: flex;
            flex-direction: column;
            .whiteText,
            .themeColorText {
                font-size: 42px;
                font-family: 'Inter-Bold';
                font-weight: 700;
                line-height: 50px;
            }
            .whiteText {
                color: #fff;
            }
            .themeColorText {
                color: #bfff00;
            }
        }
        .infoContent {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 350px;
            .hintContent {
                display: flex;
                flex-direction: column;
                margin-bottom: 65px;
                .titleText {
                    color: #fff;
                    font-size: 18px;
                    font-family: 'Inter-Bold';
                    font-weight: 600;
                    line-height: 35px;
                    letter-spacing: -0.36px;
                    margin-bottom: 10px;
                }
                .descItem {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    img {
                        width: 28px;
                        height: 28px;
                        margin-right: 26px;
                        margin-top: 3.6px;
                    }
                    span {
                        width: 292px;
                        color: #fff;
                        font-size: 18px;
                        font-family: 'Inter-Light';
                        font-weight: 300;
                        line-height: 40px;
                    }
                }
            }
            .formContent {
                width: 350px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .titleText {
                    color: #fff;
                    font-size: 18px;
                    font-family: 'Inter-SemiBold';
                    font-weight: 600;
                    line-height: 35px;
                    letter-spacing: -0.36px;
                    margin-bottom: 20px;
                }
                .exploreOurOfferWrap-login {
                    width: 100%;
                }
                // input {
                //   color: #fff;
                //   font-size: 18px;
                //   font-family: "Inter-SemiBold";
                //   font-weight: 600;
                //   line-height: 35px;
                //   letter-spacing: -0.36px;
                //   border: none;
                // }
                // ::-webkit-input-placeholder {
                //   /*Webkit browsers*/
                //   color: #8a8a8a;
                //   /* Form_placeholder */
                //   font-size: 18px;
                //   font-family: "Inter-SemiBold";
                //   font-weight: 600;
                //   line-height: 35px;
                //   letter-spacing: -0.36px;
                // }
                // :-moz-placeholder {
                //   color: #8a8a8a;
                //   /* Form_placeholder */
                //   font-size: 18px;
                //   font-family: "Inter-SemiBold";
                //   font-weight: 600;
                //   line-height: 35px;
                //   letter-spacing: -0.36px;
                // }
                // ::moz-placeholder {
                //   color: #8a8a8a;
                //   /* Form_placeholder */
                //   font-size: 18px;
                //   font-family: "Inter-SemiBold";
                //   font-weight: 600;
                //   line-height: 35px;
                //   letter-spacing: -0.36px;
                // }
                // :-ms-input-placeholder {
                //   color: #8a8a8a;
                //   /* Form_placeholder */
                //   font-size: 18px;
                //   font-family: "Inter-SemiBold";
                //   font-weight: 600;
                //   line-height: 35px;
                //   letter-spacing: -0.36px;
                // }
                // /* Input container */
                // .input-container {
                //   position: relative;
                //   width: 100%;
                //   height: 52px;
                //   margin-bottom: 28px;
                // }
                // /* Input field */
                // .input-field {
                //   display: block;
                //   width: 100%;
                //   font-size: 16px;
                //   border: none;
                //   outline: none;
                //   background-color: transparent;
                //   height: 52px;
                // }
                // /* Input label */
                // .input-label {
                //   position: absolute;
                //   top: 0;
                //   left: 0;
                //   font-size: 16px;
                //   color: rgba(204, 204, 204, 0);
                //   pointer-events: none;
                //   transition: all 0.3s ease;
                // }
                // /* Input highlight */
                // .input-highlight {
                //   position: absolute;
                //   bottom: 0;
                //   left: 0;
                //   height: 1px;
                //   width: 0;
                //   background-color: #bfff00;
                //   transition: all 0.3s ease;
                //   z-index: 2;
                // }
                // .input-highNone {
                //   position: absolute;
                //   bottom: 0;
                //   left: 0;
                //   height: 1px;
                //   width: 100%;
                //   background-color: #ccc;
                //   transition: all 0.3s ease;
                // }
                // /* Input field:focus styles */
                // .input-field:focus + .input-label {
                //   top: -20px;
                //   color: #8a8a8a;
                //   font-size: 14px;
                //   font-family: "Inter-SemiBold";
                //   font-weight: 600;
                //   letter-spacing: -0.28px;
                // }
                // .input-field:focus + .input-label + .input-highlight {
                //   width: 100%;
                // }
                // .joinContent {
                //   margin-top: 38px;
                //   width: 100%;
                //   display: flex;
                //   flex-direction: row;
                //   justify-content: flex-end;
                //   .joinBtn {
                //     width: 280px;
                //     height: 54px;
                //     background: #bfff00;
                //     display: flex;
                //     align-items: center;
                //     cursor: pointer;
                //     border: none;
                //     .btnText {
                //       background: #000;
                //       width: 224px;
                //       height: 50px;
                //       display: flex;
                //       justify-content: center;
                //       align-items: center;
                //       margin-left: 2px;
                //       transition-duration: 0.42s;
                //       span {
                //         color: #bfff00;
                //         font-size: 13px;
                //         font-family: "Inter-Bold";
                //         font-weight: 700;
                //         line-height: 14px;
                //         letter-spacing: 3.25px;
                //         text-transform: uppercase;
                //       }
                //     }
                //     .arrowImg {
                //       flex: 1;
                //       display: flex;
                //       justify-content: center;
                //       align-items: center;
                //     }
                //   }
                //   .joinBtn:hover {
                //     .btnText {
                //       background: #373737;
                //     }
                //   }
                // }
            }
        }
    }
}
