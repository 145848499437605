@media (min-width: 1440px) {
    .goToTopWrap {
        width: 100vw;
        // padding: 0 60px;
        padding: 0 34px;
        height: 45px;
        background: #fff;
        margin-top: 120px;
        display: flex;
        // justify-content: center;
        justify-content: space-between;
        align-items: center;
        // .infoContent {
        //   width: 1440px;
        //   display: flex;
        //   flex-direction: row;
        //   justify-content: space-between;
        //   align-items: center;
        .gotoBtn {
            span {
                color: #000;
                font-size: 13px;
                font-family: 'Inter-Regular';
                line-height: 14px;
                margin-right: 12px;
            }
        }
        .hintText {
            color: #000;
            text-align: right;
            font-size: 13px;
            font-family: 'Inter-Regular';
            line-height: 14px;
        }
    }
    // }
}
@media (min-width: 1024px) and (max-width: 1439px) {
    .goToTopWrap {
        width: 100vw;
        // padding: 0 60px;
        padding: 0 34px;
        height: 45px;
        background: #fff;
        margin-top: 120px;
        display: flex;
        // justify-content: center;
        justify-content: space-between;
        align-items: center;
        // .infoContent {
        //   width: 1440px;
        //   display: flex;
        //   flex-direction: row;
        //   justify-content: space-between;
        //   align-items: center;
        .gotoBtn {
            span {
                color: #000;
                font-size: 13px;
                font-family: 'Inter-Regular';
                line-height: 14px;
                margin-right: 12px;
            }
        }
        .hintText {
            color: #000;
            text-align: right;
            font-size: 13px;
            font-family: 'Inter-Regular';
            line-height: 14px;
        }
    }
    // }
}
@media (max-width: 1023px) {
    .goToTopWrap {
        width: 100vw;
        padding: 0 20px;
        height: 65px;
        background: #fff;
        margin-top: 65px;
        display: flex;
        // justify-content: center;
        justify-content: space-between;
        align-items: center;
        // .infoContent {
        //   width: 1440px;
        //   display: flex;
        //   flex-direction: row;
        //   justify-content: space-between;
        //   align-items: center;
        .gotoBtn {
            span {
                color: #000;
                font-size: 13px;
                font-family: 'Inter-Regular';
                line-height: 14px;
                margin-right: 12px;
            }
        }
        .hintText {
            color: #000;
            text-align: right;
            font-size: 13px;
            font-family: 'Inter-Regular';
            line-height: 14px;
        }
    }
    // }
}
