.platformInfoModalWrapPc {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 99;
    background: rgba(138, 138, 138, 0.4);
    backdrop-filter: blur(42px);
    display: flex;
    justify-content: center;
    align-items: center;
    .infoContent {
        width: 874px;
        border: 1px solid #8a8a8a;
        background: #000;
        position: relative;
        padding: 66px 100px 66px 0;
        display: flex;
        flex-direction: row;
        .closeBtn {
            position: absolute;
            right: 30px;
            top: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        .coverContent {
            width: 392px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 77px;
            .logoImg {
                width: 127px;
                height: 127px;
                margin-bottom: 25px;
            }
            .titletext {
                width: 292px;
                margin-bottom: 30px;
                color: #fff;
                text-align: center;
                font-size: 34px;
                font-family: 'Inter-Bold';
                font-weight: 700;
                line-height: 40px;
            }
            .btnContent {
                display: flex;
                flex-direction: column;
                align-items: center;
                .btnItem {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100px;
                    border: 1px solid #8a8a8a;
                    margin-bottom: 14px;
                    padding: 0 15px;
                    cursor: pointer;
                    transition-duration: 0.42s;
                    span {
                        color: #8a8a8a;
                        font-size: 18px;
                        font-family: 'Inter-Regular';
                        line-height: 35px;
                        letter-spacing: -0.36px;
                        margin-right: 15px;
                        transition-duration: 0.42s;
                    }
                    .img {
                        width: 16px;
                        height: 15px;
                        background-image: url('./imgs/arrow.svg');
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: cover;
                    }
                }
                .btnItem:hover {
                    background: #bfff00;
                    span {
                        color: #000;
                    }
                    .img {
                        background-image: url('./imgs/arrowNone.svg');
                    }
                }
            }
            .platformContent {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 70px;
                cursor: pointer;
                .discordLogo {
                    background-image: url('./imgs/discordLogoBefore.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                }
                .twitterLogo {
                    background-image: url('./imgs/twitterLogoBefore.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                }
                .discordLogo:hover {
                    background-image: url('./imgs/discordLogoAfter.svg');
                }
                .twitterLogo:hover {
                    background-image: url('./imgs/twitterLogoAfter.svg');
                }
                .btnPlatformItem {
                    width: 30px;
                    height: 30px;
                }
            }
        }
        .descContent {
            display: flex;
            flex-direction: column;
            margin-top: 26px;
            .descText {
                color: #c9c7c7;
                font-size: 18px;
                font-family: 'Inter-SemiBold';
                font-weight: 600;
                line-height: 40px;
                letter-spacing: -0.36px;
                margin-bottom: 24px;
            }
            .hintContent {
                .hintItem {
                    display: flex;
                    margin-bottom: 10px;
                    .checkIcon {
                        margin-right: 20px;
                    }
                    .arrowIcon {
                        margin-left: 20px;
                    }
                    .lineValue {
                        position: relative;
                        bottom: 0;
                        span {
                            transition-duration: 0.42s;
                            border-bottom: 1px #000 solid;
                            cursor: pointer;
                            color: #fff;
                            font-size: 18px;
                            font-family: 'Inter-Light';
                            line-height: 35px;
                            letter-spacing: -0.36px;
                            margin-bottom: 2px;
                        }
                        .line {
                            transition-duration: 0.42s;
                        }
                    }
                }
                .hintItem:hover {
                    .lineValue {
                        span {
                            border-bottom: 1px #000 solid;
                            cursor: pointer;
                        }
                        .line {
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            display: block;
                            height: 1px;
                            background: #bfff00;
                        }
                    }
                }
            }
        }
    }
}
.platformInfoModalWrapPhone {
    width: 100vw;
    height: 100vh;
    overflow: auto;
    padding: 20px;
    position: fixed;
    top: 0;
    z-index: 99;
    background: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    .infoContent {
        border: 1px #8a8a8a solid;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
        position: relative;
        .closeBtn {
            position: absolute;
            right: 30px;
            top: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        .coverContent {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .logoImg {
                width: 128px;
                height: 128px;
                margin-bottom: 24px;
            }
            .titletext {
                width: 350px;
                color: #fff;
                text-align: center;
                font-size: 34px;
                font-family: 'Inter-Bold';
                font-weight: 700;
                line-height: 40px;
                margin-bottom: 36px;
            }
            .descText {
                width: 300px;
                color: #c9c7c7;
                text-align: center;
                font-size: 18px;
                font-family: 'Inter-SemiBold';
                font-weight: 600;
                line-height: 35px;
                letter-spacing: -0.36px;
                margin-bottom: 40px;
            }
            .hintContent {
                width: 300px;
                .hintItem {
                    display: flex;
                    align-items: center;
                    .checkIcon {
                        margin-right: 10px;
                    }
                    .arrowIcon {
                        margin-left: 10px;
                    }
                    .lineValue {
                        position: relative;
                        bottom: 0;
                        span {
                            transition-duration: 0.42s;
                            border-bottom: 1px #000 solid;
                            cursor: pointer;
                            color: #fff;
                            font-size: 14px;
                            font-family: 'Inter-Light';
                            line-height: 35px;
                            letter-spacing: -0.36px;
                            margin-bottom: 2px;
                        }
                        .line {
                            transition-duration: 0.42s;
                        }
                    }
                }
                .hintItem:hover {
                    .lineValue {
                        span {
                            border-bottom: 1px #bfff00 solid;
                            cursor: pointer;
                        }
                    }
                }
            }
            .btnContent {
                margin-top: 46px;
                display: flex;
                flex-direction: column;
                align-items: center;
                .btnItem {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100px;
                    border: 1px solid #8a8a8a;
                    margin-bottom: 14px;
                    padding: 0 15px;
                    cursor: pointer;
                    transition-duration: 0.42s;
                    span {
                        color: #8a8a8a;
                        font-size: 18px;
                        font-family: 'Inter-Regular';
                        line-height: 35px;
                        letter-spacing: -0.36px;
                        margin-right: 15px;
                        transition-duration: 0.42s;
                    }
                    .img {
                        width: 16px;
                        height: 15px;
                        background-image: url('./imgs/arrow.svg');
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: cover;
                    }
                }
                .btnItem:hover {
                    background: #bfff00;
                    span {
                        color: #000;
                    }
                    .img {
                        background-image: url('./imgs/arrowNone.svg');
                    }
                }
            }
            .platformContent {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 70px;
                margin-bottom: 20px;
                cursor: pointer;
                .discordLogo {
                    background-image: url('./imgs/discordLogoBefore.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                }
                .twitterLogo {
                    background-image: url('./imgs/twitterLogoBefore.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                }
                .discordLogo:hover {
                    background-image: url('./imgs/discordLogoAfter.svg');
                }
                .twitterLogo:hover {
                    background-image: url('./imgs/twitterLogoAfter.svg');
                }
                .btnPlatformItem {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
}
@keyframes platformInfoModalWrapRightToLeft {
    0% {
        opacity: 0;
        transform: translate(0, -30px);
    }
    100% {
        opacity: 1;
        z-index: 999;
        transform: translate(0, 0);
    }
}

@keyframes platformInfoModalWrapLeftToRight {
    0% {
        opacity: 1;
        transform: translate(0, 0);
    }
    100% {
        opacity: 0;
        z-index: 0;
        transform: translate(0, -30px);
    }
}
