@media (min-width: 1440px) {
    .ourMembersWrap {
        width: 1440px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .comTitle {
            width: 100%;
            // margin-top: 100px;
            margin-top: 90px;
            margin-bottom: 48px;
            display: flex;
            flex-direction: column;
            .whiteText,
            .themeColorText {
                font-size: 65px;
                font-family: 'Inter-Bold';
                font-weight: 700;
                line-height: 65px;
                letter-spacing: -1.3px;
            }
            .whiteText {
                color: #fff;
            }
            .themeColorText {
                color: #bfff00;
            }
        }
        .infoTableContent {
            display: flex;
            flex-direction: row;
            width: 100%;
            border-top: 1px solid #8a8a8a;
            .leftCopntent {
                margin-right: 20px;
            }
            .leftCopntent,
            .rightCopntent {
                display: flex;
                flex-direction: column;
                flex: 1;
                .itemTableContent {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 178px;
                    border-top: 1px solid rgba(138, 138, 138, 0.2);
                    transition: all 0.3s linear;
                    .coverImg {
                        width: 200px;
                        height: 138px;
                        margin-right: 28px;
                        flex-shrink: 0;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .descContent {
                        display: flex;
                        flex-direction: column;
                        color: #fff;
                        .title {
                            font-family: 'Inter-Bold';
                            font-size: 13px;
                            font-weight: 700;
                            line-height: 30px;
                            margin-bottom: 10px;
                        }
                        .desc {
                            display: flex;
                            flex-direction: row;
                            span {
                                font-family: 'Inter-Regular';
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 35px;
                                letter-spacing: -0.36px;
                                transition-duration: 0.42s;
                                cursor: pointer;
                                border-bottom: 1px #131313 solid;
                            }
                            span:hover {
                                border-bottom: 1px solid #bfff00;
                            }
                            .arrowIcon {
                                width: 14px;
                                height: 100%;
                                margin-left: 10px;
                                img {
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                }
                .itemTableContent:hover {
                    background-color: rgba(191, 255, 0, 0.04);
                }
                .infoContent {
                    padding: 53px 45px;
                    background: #171717;
                    border-top: 1px solid #8a8a8a;
                    .titleText {
                        color: #fff;
                        font-size: 18px;
                        font-family: 'Inter';
                        font-weight: 700;
                        line-height: 40px;
                        margin-bottom: 19px;
                    }
                    .descText {
                        display: flex;
                        align-items: center;
                        span {
                            color: #c9c7c7;
                            font-size: 18px;
                            font-family: 'Inter-Regular';
                            line-height: 35px;
                            letter-spacing: -0.36px;
                            margin-right: 10px;
                            transition-duration: 0.42s;
                            // border-bottom: 1px #131313 solid;
                            cursor: pointer;
                        }
                        span:hover {
                            border-bottom: 1px #bfff00 solid;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1024px) and (max-width: 1439px) {
    .ourMembersWrap {
        width: 976px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .comTitle {
            width: 100%;
            margin-top: 100px;
            margin-bottom: 15px;
            display: flex;
            flex-direction: column;
            .whiteText,
            .themeColorText {
                font-size: 42px;
                font-family: 'Inter-Bold';
                font-weight: 700;
                line-height: 50px;
                letter-spacing: -1.3px;
            }
            .whiteText {
                color: #fff;
            }
            .themeColorText {
                color: #bfff00;
            }
        }
        .infoTableContent {
            display: flex;
            flex-direction: row;
            width: 100%;
            border-top: 1px solid #8a8a8a;
            .leftCopntent {
                margin-right: 20px;
            }
            .leftCopntent {
                display: flex;
                flex-direction: column;
                width: 580px;
                .itemTableContent {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 176px;
                    border-top: 1px solid rgba(138, 138, 138, 0.2);
                    .coverImg {
                        width: 176px;
                        height: 136px;
                        margin-right: 20px;
                        flex-shrink: 0;
                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .descContent {
                        display: flex;
                        flex-direction: column;
                        color: #fff;
                        .title {
                            font-family: 'Inter-Bold';
                            font-size: 13px;
                            font-weight: 700;
                            line-height: 30px;
                            margin-bottom: 10px;
                        }
                        .desc {
                            display: flex;
                            flex-direction: row;
                            span {
                                font-family: 'Inter-Regular';
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 35px;
                                letter-spacing: -0.36px;
                                transition-duration: 0.42s;
                                cursor: pointer;
                                // border-bottom: 1px #131313 solid;
                            }
                            span:hover {
                                border-bottom: 1px solid #131313;
                            }
                            .arrowIcon {
                                width: 14px;
                                height: 100%;
                                margin-left: 10px;
                                img {
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                }
            }
            .rightCopntent {
                flex: 1;
                display: flex;
                flex-direction: column;
                .infoContent {
                    padding: 40px 44px;
                    background: #171717;
                    .titleText {
                        color: #fff;
                        font-size: 18px;
                        font-family: 'Inter';
                        font-weight: 700;
                        line-height: 40px;
                        margin-bottom: 26px;
                    }
                    .descText {
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;
                        span {
                            color: #c9c7c7;
                            font-size: 18px;
                            font-family: 'Inter-Regular';
                            line-height: 35px;
                            letter-spacing: -0.36px;
                            margin-right: 10px;
                            transition-duration: 0.42s;
                            // border-bottom: 1px #131313 solid;
                            cursor: pointer;
                        }
                        span:hover {
                            border-bottom: 1px solid #131313;
                        }
                        .arrowIcon {
                            width: 14px;
                            height: 71px;
                            margin-left: 10px;
                            img {
                                margin-top: 11px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 1023px) {
    .ourMembersWrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .comTitle {
            width: 100%;
            padding: 0 16px;
            margin-top: 65px;
            margin-bottom: 40px;
            display: flex;
            flex-direction: column;
            .whiteText,
            .themeColorText {
                font-size: 42px;
                font-family: 'Inter-Bold';
                font-weight: 700;
                line-height: 50px;
            }
            .whiteText {
                color: #fff;
            }
            .themeColorText {
                color: #bfff00;
            }
        }
        .infoTableContent {
            width: 100%;
            padding: 0 16px;
            display: flex;
            flex-direction: column;
            .itemTableContent {
                display: flex;
                flex-direction: column;
                border-top: 1px solid rgba(138, 138, 138, 0.2);
                margin-bottom: 30px;
                .coverImg {
                    width: 100%;
                    margin-right: 20px;
                    flex-shrink: 0;
                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
                .descContent {
                    display: flex;
                    flex-direction: column;
                    color: #fff;
                    .title {
                        font-family: 'Inter-Bold';
                        font-size: 13px;
                        font-weight: 700;
                        line-height: 30px;
                        margin-bottom: 10px;
                    }
                    .desc {
                        display: flex;
                        flex-direction: row;
                        span {
                            font-family: 'Inter-Regular';
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 35px;
                            letter-spacing: -0.36px;
                            transition-duration: 0.42s;
                            cursor: pointer;
                            // border-bottom: 1px #131313 solid;
                        }
                        span:hover {
                            // border-bottom: 1px #bfff00 solid;
                            border-bottom: 1px solid #131313;
                        }
                        .arrowIcon {
                            width: 14px;
                            height: 100%;
                            margin-left: 10px;
                            img {
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
            .infoContent {
                background: #171717;
                margin-bottom: 30px;
                .titleText {
                    color: #fff;
                    font-size: 18px;
                    font-family: 'Inter';
                    font-weight: 700;
                    line-height: 40px;
                    margin-bottom: 20px;
                }
                .descText {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                    span {
                        color: #c9c7c7;
                        font-size: 18px;
                        font-family: 'Inter-Regular';
                        line-height: 35px;
                        letter-spacing: -0.36px;
                        margin-right: 10px;
                        transition-duration: 0.42s;
                        // border-bottom: 1px #131313 solid;
                        cursor: pointer;
                    }
                    span:hover {
                        border-bottom: 1px #bfff00 solid;
                    }
                    .arrowIcon {
                        width: 14px;
                        height: 71px;
                        margin-left: 10px;
                        img {
                            margin-top: 11px;
                        }
                    }
                }
            }
        }
    }
}
