@media (min-width: 1440px) {
    .partners-wrap {
        width: 1262px;
        margin: auto;
        margin-bottom: -11px;
        .partners-vertical {
            width: 1px;
            height: 130px;
            margin: auto;
            margin-bottom: 65px;
        }
        .partners-wrap-title {
            text-align: center;
            color: #fff;
            font-family: 'Inter-Bold';
            font-size: 42px;
            line-height: 50px;
            margin-bottom: 65px;
        }
        .partners-pc-wrap {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            .partners-pc-box {
                width: 205px;
                height: 65px;
                display: block;
                cursor: pointer;
                margin-right: 58px;
                margin-bottom: 58px;
                img {
                    width: 100%;
                    // height: 100%;
                    object-fit: cover;
                }
            }
            .partners-pc-box:nth-child(5n) {
                margin-right: 0 !important;
            }
        }
    }
}
@media (min-width: 1024px) and (max-width: 1439px) {
    .partners-wrap {
        width: 100%;
        margin: auto;
        margin-bottom: 20px;
        position: relative;
        .partners-vertical {
            width: 1px;
            height: 130px;
            margin: auto;

            margin-bottom: 62px;
            margin-top: 65px;
        }
        .partners-wrap-title {
            text-align: center;
            color: #fff;
            font-family: 'Inter-Bold';
            font-size: 42px;
            line-height: 50px;
            margin-bottom: 72px;
        }
        .partners-table-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // position: relative;
            width: calc(100% - 220px);
            margin: auto;
            .arrow-common {
                width: 46px;
                height: 44px;
                border: 1px solid #bfff00;
                background: #bfff00;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 17px;
                    height: 16px;
                    object-fit: cover;
                }
            }
            .partners-pc-wrap {
                // flex:1;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                // margin: 0 30px;
                .partners-pc-box {
                    width: 220px;
                    height: 65px;
                    display: block;
                    cursor: pointer;
                    // margin-right: 71px;
                    margin-right: 50px;
                    margin-bottom: 59px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                    img:nth-child(3n) {
                        margin-right: 0;
                    }
                }
            }
            .par-swiper {
                padding-bottom: 15px;
                .swiper-pagination-bullets {
                    bottom: 0 !important;
                }
                .swiper-pagination-bullet {
                    width: 9px;
                    height: 9px;
                    background: #8a8a8a;
                }

                .swiper-pagination-bullet-active {
                    background: #bfff00 !important;
                }
            }
            .swiper-button-prev,
            .swiper-button-next {
                width: 46px;
                height: 44px;
                border: 1px solid #bfff00;
                background: #bfff00;
                position: absolute;
                top: 70%;
                // width: 50px;
                // height: 50px;
                // background: red;
                &::after {
                    display: none;
                }

                &.disable {
                    // background: royalblue;
                }
            }
        }
        .partners-table-dian {
            list-style: none;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 57px;
            height: 9px;
            margin: auto;
            margin-top: 15px;
            cursor: pointer;
            li {
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background: #8a8a8a;
            }
            .li_active {
                background: #bfff00 !important;
            }
        }
    }
}
@media (max-width: 1023px) {
    .partners-wrap {
        width: 100%;
        margin: auto;
        .partners-vertical {
            width: 1px;
            height: 130px;
            margin: auto;
            margin-bottom: 62px;
            margin-top: 64px;
        }
        .partners-wrap-title {
            text-align: center;
            color: #fff;
            font-family: 'Inter-Bold';
            font-size: 42px;
            line-height: 50px;
            margin-bottom: 65px;
        }
        .partners-table-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .arrow-common {
                display: none;
                // width: 46px;
                // height: 44px;
                // border: 1px solid #BFFF00;
                // background: #BFFF00;
                // cursor: pointer;
                // display: flex;
                // align-items: center;
                // justify-content: center;

                // img {
                //   width: 17px;
                //   height: 16px;
                //   object-fit: cover;
                // }
            }
            .partners-pc-wrap {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                padding: 0 23px;
                overflow: hidden;

                .partners-pc-box {
                    width: 150px;
                    height: 54px;
                    display: block;
                    cursor: pointer;
                    margin-bottom: 50px;
                    img {
                        width: 100%;
                        // height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
        .par-swiper {
            .swiper-pagination-bullet {
                width: 9px;
                height: 9px;
                background: #8a8a8a;
            }
            .swiper-pagination-bullet-active {
                background: #bfff00 !important;
            }
        }
    }
}
