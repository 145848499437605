.flickerAnimationCom {
    width: 236px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    @media (min-width: 1440px) {
        width: 316px;
    }
    .rowItem {
        margin-bottom: 34px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .item {
            width: 4px;
            height: 4px;
            background: #bfff00;
        }
    }
}
@keyframes noneOrDisplay {
    0% {
        opacity: 0.6;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0.6;
    }
}
