@media (min-width: 1440px) {
    .meetsCapitalWrap {
        width: 1440px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .comTitle {
            width: 1312px;
            margin-top: 120px;
            margin-bottom: 48px;
            display: flex;
            flex-direction: column;
            .whiteText,
            .themeColorText {
                font-size: 65px;
                font-family: 'Inter-Bold';
                font-weight: 700;
                line-height: 65px;
                letter-spacing: -1.3px;
            }
            .whiteText {
                color: #fff;
            }
            .themeColorText {
                color: #bfff00;
            }
        }
        .infoContent {
            width: 1312px;
            display: flex;
            flex-direction: row;
            .platformOneTable {
                width: 612px;
                height: 612px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                border: 1px solid;
                border-bottom: none;
                border: 1px solid #808080;
                .tableItem {
                    height: 33.333%;
                    width: 33.3333%;
                    border: 1px solid #808080;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition-duration: 0.42s;
                    cursor: pointer;
                    position: relative;
                    .firstIcon {
                        position: absolute;
                        left: -2px;
                        top: -2px;
                    }
                }
                .tableItem:hover {
                    background: #373737;
                }
                .tableItemNone {
                    width: 33.3333%;
                    height: 33.333%;
                    border: 1px solid #808080;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .centerCom {
                height: 612px;
                width: 101px;
                background: #bfff00;
                position: relative;
                .infoText {
                    width: 612px;
                    position: absolute;
                    bottom: 281px;
                    left: -252.5px;
                    transform: translateX(-50%);
                    color: #121212;
                    text-align: center;
                    font-size: 42px;
                    font-family: 'Inter-Bold';
                    font-weight: 700;
                    line-height: 50px;
                    transform: rotate(-90deg);
                }
            }
            .platformTwoTable {
                flex: 1;

                .tableItem {
                    background: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition-duration: 0.42s;
                    cursor: pointer;
                    & > img {
                        transform: scale(100%);
                    }
                }
                .tableItem:hover {
                    background: #f8ffe4;
                }
                .tableItemNone {
                    height: 152px;
                    width: 50%;
                    background: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #808080;
                }
            }
        }
        .joinContent {
            width: 1312px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-top: -2px;
            .joinBtn {
                width: 280px;
                height: 54px;
                background: #bfff00;
                display: flex;
                align-items: center;
                cursor: pointer;
                .btnText {
                    background: #000;
                    width: 224px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 2px;
                    transition-duration: 0.42s;
                    span {
                        color: #bfff00;
                        font-size: 13px;
                        font-family: 'Inter-Bold';
                        font-weight: 700;
                        line-height: 14px;
                        letter-spacing: 3.25px;
                        text-transform: uppercase;
                    }
                }
                .arrowImg {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .joinBtn:hover {
                .btnText {
                    background: #373737;
                }
            }
        }
    }
}
@media (min-width: 1024px) and (max-width: 1439px) {
    .meetsCapitalWrap {
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        .comTitle {
            width: 100%;
            padding: 0 34px;
            margin-top: 120px;
            margin-bottom: 48px;
            display: flex;
            flex-direction: column;
            max-width: 1312px;
            .whiteText,
            .themeColorText {
                font-family: 'Inter-Bold';
                font-size: 42px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px;
            }
            .whiteText {
                color: #fff;
            }
            .themeColorText {
                color: #bfff00;
            }
        }
        .infoContent {
            width: 100%;
            padding: 0 34px;
            display: flex;
            max-width: 1312px;
            flex-direction: row;
            .platformOneTable {
                width: 450px;
                display: grid;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                height: 450px;
                border: 1px solid #808080;
                border-bottom: none;
                .tableItem {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition-duration: 0.42s;
                    cursor: pointer;
                    position: relative;
                    .firstIcon {
                        position: absolute;
                        left: -2px;
                        top: -2px;
                    }
                    & > img {
                        transform: scale(75%);
                    }
                }
                .tableItem:hover {
                    background: #373737;
                }
                .tableItemNone {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .centerCom {
                height: 450px;
                width: 74px;
                background: #bfff00;
                position: relative;
                .infoText {
                    width: 450px;
                    position: absolute;
                    bottom: 225px;
                    left: -188px;
                    transform: translateX(-50%);
                    font-family: 'Inter-Bold';
                    color: #121212;
                    text-align: center;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 30px;
                    transform: rotate(-90deg);
                }
            }
            .platformTwoTable {
                flex: 1;
                height: 450px;
                flex-direction: row;
                flex-wrap: wrap;
                .tableItem {
                    background: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition-duration: 0.42s;
                    cursor: pointer;
                    & > img {
                        transform: scale(75%);
                    }
                }
                .tableItem:hover {
                    background: #f8ffe4;
                }
                .tableItemNone {
                    height: 113px;
                    width: 50%;
                    background: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .joinContent {
            width: 976px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-top: -2px;
            .joinBtn {
                width: 280px;
                height: 54px;
                background: #bfff00;
                display: flex;
                align-items: center;
                cursor: pointer;
                .btnText {
                    background: #000;
                    width: 224px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 2px;
                    transition-duration: 0.42s;
                    span {
                        color: #bfff00;
                        font-size: 13px;
                        font-family: 'Inter-Bold';
                        font-weight: 700;
                        line-height: 14px;
                        letter-spacing: 3.25px;
                        text-transform: uppercase;
                    }
                }
                .arrowImg {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .joinBtn:hover {
                .btnText {
                    background: #373737;
                }
            }
        }
    }
}
@media (max-width: 1023px) {
    .meetsCapitalWrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .comTitle {
            width: 100%;
            padding: 0 16px;
            margin-top: 65px;
            margin-bottom: 40px;
            display: flex;
            flex-direction: column;
            .whiteText,
            .themeColorText {
                font-size: 42px;
                font-family: 'Inter-Bold';
                font-weight: 700;
                line-height: 50px;
            }
            .whiteText {
                color: #fff;
            }
            .themeColorText {
                color: #bfff00;
            }
        }
        .infoContent {
            padding: 0 16px;
            width: 100%;
            display: flex;
            flex-direction: column;
            .platformOneTable {
                display: grid;
                border: 1px solid #808080;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                border-bottom: none;
                .tableItem {
                    height: 180px;
                    border: 1px solid #808080;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition-duration: 0.42s;
                    cursor: pointer;
                    position: relative;
                    .firstIcon {
                        position: absolute;
                        left: -2px;
                        top: -2px;
                    }
                }
                .tableItem:hover {
                    background: #373737;
                }
                .tableItemNone {
                    height: 180px;
                    border: 1px solid #808080;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .centerCom {
                height: 100px;
                width: 100%;
                background: #bfff00;
                display: flex;
                justify-content: center;
                align-items: center;
                .infoText {
                    color: #121212;
                    text-align: center;
                    font-size: 28px;
                    font-family: 'Inter-Bold';
                    font-weight: 700;
                    line-height: 30px;
                }
            }
            .platformTwoTable {
                grid-template-columns: repeat(3, minmax(0, 1fr));
                .tableItem {
                    height: 150px;
                    background: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition-duration: 0.42s;
                    cursor: pointer;
                    border-bottom: 1px solid #808080;
                }
                .tableItem:hover {
                    background: #f8ffe4;
                }
            }
        }
        .joinContent {
            width: 360px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-top: -2px;
            .joinBtn {
                width: 280px;
                height: 54px;
                background: #bfff00;
                display: flex;
                align-items: center;
                cursor: pointer;
                .btnText {
                    background: #000;
                    width: 224px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 2px;
                    transition-duration: 0.42s;
                    span {
                        color: #bfff00;
                        font-size: 13px;
                        font-family: 'Inter-Bold';
                        font-weight: 700;
                        line-height: 14px;
                        letter-spacing: 3.25px;
                        text-transform: uppercase;
                    }
                }
                .arrowImg {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .joinBtn:hover {
                .btnText {
                    background: #373737;
                }
            }
        }
    }
}

.meetsCapitalWrap {
    .border-b-0 {
        border-bottom: 0 !important;
    }
    .border-r-0 {
        border-right: 0 !important;
    }
    .border-l-0 {
        border-left: 0 !important;
    }
    .border-t-0 {
        border-top: 0 !important;
    }
}
