@media (min-width: 1440px) {
    .webEventsWrap {
        width: 1440px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .comTitle {
            width: 1312px;
            margin-top: 120px;
            margin-bottom: 48px;
            display: flex;
            flex-direction: column;
            .whiteText,
            .themeColorText {
                font-size: 65px;
                font-family: 'Inter-Bold';
                font-weight: 700;
                line-height: 65px;
                letter-spacing: -1.3px;
            }
            .whiteText {
                color: #fff;
            }
            .themeColorText {
                color: #bfff00;
            }
        }
        .infoContent {
            width: 1312px;
            height: 741px;
            // display: flex;
            // flex-direction: row;
            .cardItemContent {
                width: 33.3333%;
                border: 1px solid #8a8a8a;
                display: flex;
                flex-direction: column;
                .cardCover {
                    height: 260px;
                    width: 100%;
                    background: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .descInfoContent {
                    display: flex;
                    flex-direction: column;
                    padding: 60px 40px 0 40px;
                    background: #000;
                    flex: 1;
                    .dateText {
                        color: #bfff00;
                        font-size: 13px;
                        font-family: 'Inter-Bold';
                        font-weight: 700;
                        line-height: 30px;
                    }
                    .titleText {
                        color: #fff;
                        font-size: 34px;
                        font-family: 'Inter-Bold';
                        font-weight: 700;
                        line-height: 40px;
                        margin-bottom: 30px;
                        height: 100px;
                    }

                    .special-desc {
                        color: var(--fossil, #c9c7c7);
                        font-family: 'Inter-Medium';
                        font-size: 13px;
                        line-height: 25px;
                        letter-spacing: 1.3px;
                        text-transform: uppercase;
                        margin-top: 5px;
                    }
                    .descTextItem {
                        display: flex;
                        align-items: flex-start;
                        margin-bottom: 30px;
                        img {
                            margin-right: 15px;
                        }
                        span {
                            color: #c9c7c7;
                            font-size: 18px;
                            font-family: 'Inter-Light';
                            font-weight: 300;
                            line-height: 25px;
                        }
                    }
                }
            }
        }
        .swiperBtnContent {
            margin-top: 20px;
            display: flex;
            img {
                cursor: pointer;
            }
        }
    }
}
@media (min-width: 1024px) and (max-width: 1439px) {
    .webEventsWrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        .comTitle {
            width: 976px;
            margin-top: 120px;
            margin-bottom: 48px;
            display: flex;
            flex-direction: column;
            .whiteText,
            .themeColorText {
                font-family: 'Inter-Bold';
                font-size: 42px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px;
            }
            .whiteText {
                color: #fff;
            }
            .themeColorText {
                color: #bfff00;
            }
        }
        .infoContent {
            width: 976px;
            height: 741px;
            .cardItemContent {
                width: 50%;
                border: 1px solid #8a8a8a;
                display: flex;
                flex-direction: column;
                .cardCover {
                    height: 260px;
                    width: 100%;
                    background: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .descInfoContent {
                    display: flex;
                    flex-direction: column;
                    padding: 60px 40px 0 40px;
                    background: #000;
                    flex: 1;
                    .dateText {
                        color: #bfff00;
                        font-size: 13px;
                        font-family: 'Inter-Bold';
                        font-weight: 700;
                        line-height: 30px;
                    }
                    .titleText {
                        color: #fff;
                        font-size: 34px;
                        font-family: 'Inter-Bold';
                        font-weight: 700;
                        line-height: 40px;
                        margin-bottom: 30px;
                        height: 100px;
                    }
                    .special-desc {
                        color: var(--fossil, #c9c7c7);
                        font-family: 'Inter-Medium';
                        font-size: 13px;
                        line-height: 25px;
                        letter-spacing: 1.3px;
                        text-transform: uppercase;
                        margin-top: 5px;
                    }
                    .descTextItem {
                        display: flex;
                        align-items: flex-start;
                        margin-bottom: 30px;
                        img {
                            margin-right: 15px;
                        }
                        span {
                            color: #c9c7c7;
                            font-size: 18px;
                            font-family: 'Inter-Light';
                            font-weight: 300;
                            line-height: 25px;
                        }
                    }
                }
            }
        }
        .swiperBtnContent {
            margin-top: 20px;
            display: flex;
            img {
                cursor: pointer;
            }
        }
    }
}
@media (max-width: 1023px) {
    .webEventsWrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .comTitle {
            width: 350px;
            margin-top: 65px;
            margin-bottom: 48px;
            display: flex;
            flex-direction: column;
            .whiteText,
            .themeColorText {
                font-size: 42px;
                font-family: 'Inter-Bold';
                font-weight: 700;
                line-height: 50px;
            }
            .whiteText {
                color: #fff;
            }
            .themeColorText {
                color: #bfff00;
            }
        }
        .infoContent {
            width: 350px;
            .cardItemContent {
                width: 100%;
                border: 1px solid #8a8a8a;
                .cardCover {
                    height: 260px;
                    width: 100%;
                    background: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .descInfoContent {
                    display: flex;
                    flex-direction: column;
                    padding: 20px 30px 0 30px;
                    background: #000;
                    height: 420px;
                    .dateText {
                        color: #bfff00;
                        font-size: 13px;
                        font-family: 'Inter-Bold';
                        font-weight: 700;
                        line-height: 30px;
                    }
                    .titleText {
                        color: #fff;
                        font-size: 26px;
                        font-family: 'Inter-Bold';
                        font-weight: 700;
                        line-height: 30px;
                        margin-bottom: 20px;
                    }

                    .special-desc {
                        color: var(--fossil, #c9c7c7);
                        font-family: 'Inter-Medium';
                        font-size: 13px;
                        line-height: 25px;
                        letter-spacing: 1.3px;
                        text-transform: uppercase;
                        margin-top: 5px;
                    }
                    .descTextItem {
                        display: flex;
                        align-items: flex-start;
                        margin-bottom: 30px;
                        img {
                            margin-right: 15px;
                        }
                        span {
                            color: #c9c7c7;
                            font-size: 18px;
                            font-family: 'Inter-Light';
                            font-weight: 300;
                            line-height: 25px;
                        }
                    }
                }
            }
            .ant-carousel .slick-prev {
                left: -20px !important;
            }
            .ant-carousel .slick-next {
                right: -20px !important;
            }
        }
        .swiperBtnContent {
            margin-top: 40px;
            display: flex;
            img {
                cursor: pointer;
            }
        }
    }
}
