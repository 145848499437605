.capital-wrap {
    video {
        width: 100%;
    }
    video::-webkit-media-controls {
        display: none !important;
    }
    /* 隐藏移动设备上的自带播放按钮 */
    video::-webkit-media-controls-start-playback-button,
    video::-webkit-media-controls-play-button,
    video::-webkit-media-controls-pause-button {
        display: none !important;
        -webkit-appearance: none;
    }

    /* 隐藏移动设备上的全屏按钮 */
    video::-webkit-media-controls-fullscreen-button {
        display: none !important;
        -webkit-appearance: none;
    }

    /* 隐藏移动设备上的音量控制条 */
    video::-webkit-media-controls-volume-slider,
    video::-webkit-media-controls-mute-button {
        display: none !important;
        -webkit-appearance: none;
    }

    /* 隐藏 Safari 自带的播放和暂停按钮 */
    video::-webkit-media-controls-start-playback-button,
    video::-webkit-media-controls-play-button,
    video::-webkit-media-controls-pause-button {
        display: none !important;
        -webkit-appearance: none;
    }
    .join {
        .join-swiper {
            color: #fff;
            position: relative;
            .prev,
            .next {
                width: 46px;
                height: 44px;
                background-size: contain;
                position: absolute;
                cursor: pointer;
                top: 50%;
                transform: translateY(-50%);
                z-index: 9;
            }
            .prev {
                background: url('https://buckets.bochslerfinance.com/shiku-public/assets/shiku-icon/prev.svg')
                    center no-repeat;
                left: 0;
            }
            .next {
                background: url('https://buckets.bochslerfinance.com/shiku-public/assets/shiku-icon/next.svg')
                    center no-repeat;
                right: 0;
            }
            .my-dots {
                position: absolute;
                bottom: 34px;
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                align-items: center;
                width: 100%;
                max-width: calc(100% - 300px);
                gap: 19px;
                .cell {
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    opacity: 0.5;
                    color: var(--White, #fff);
                    font-family: Inter-SemiBold;
                    line-height: 35px; /* 194.444% */
                    letter-spacing: -0.36px;
                    font-size: 18px;
                    &.active {
                        opacity: 1;
                    }
                    &::after {
                        content: '';
                        display: inline-block;
                        height: 4px;
                        width: 100%;
                        background: var(--White, #fff);
                    }
                }
            }
            .swiper-slide-join {
                position: relative;
                width: 100%;

                .play-button {
                    position: absolute;
                    top: 50%;
                    z-index: 9;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 104px;
                    height: 104px;
                    background-color: rgba(0, 0, 0, 0.5);
                    border-radius: 50%;
                    cursor: pointer;
                    background: url('https://buckets.bochslerfinance.com/shiku-public/assets/shiku-icon/Play.svg')
                        no-repeat center;
                }
            }
        }
        padding: 120px 0 65px;
        .join-top {
            max-width: 898px;
            text-align: center;
            margin: 0 auto;
        }
        .join-img-list {
            width: calc(100% - 121px);
            margin: 65px auto 120px;
            display: flex;
            align-items: center;
            gap: 25px;
            overflow: hidden;
            position: relative;
            &::after,
            &::before {
                content: '';
                display: inline-block;
                position: absolute;
            }
            &::after {
                top: -93px;
                left: 0;
                z-index: 1;
                transform: translateX(-40%);
                background: url('https://buckets.bochslerfinance.com/shiku-public/assets/shiku-icon/pattern_bottom.png')
                    no-repeat center;
                background-size: cover;
                width: 316px;
                height: 232px;
            }
            &::before {
                bottom: -90px;
                right: 0;
                z-index: 1;
                transform: translateX(40%);
                background: url('https://buckets.bochslerfinance.com/shiku-public/assets/shiku-icon/pattern_bottom-right.png')
                    no-repeat center;
                background-size: cover;
                width: 316px;
                height: 232px;
            }
            div {
                flex: 1;
                position: relative;
                z-index: 2;
            }
            img {
                object-fit: contain;
                width: 100%;
            }
        }
        background-color: #131313;
        .title {
            color: var(--Neon, #bfff00);
            font-family: Inter-Bold;
            font-size: 42px;
            line-height: 50px;
        }
        .desc {
            color: #c9c7c7;
            font-size: 18px;
            line-height: 35px;
            letter-spacing: -0.36px;
            margin: 53px 0 43px;
        }
        .join-btn {
            cursor: pointer;
            margin: 0 auto;
            height: 54px;
            background: #000;
            border: 1px solid #bfff00;
            display: flex;
            align-items: center;
            max-width: 288px;
            .capital-reg-right-title {
                flex: 1;
                text-align: center;
                line-height: 54px;
                color: #bfff00;
                font-family: 'Inter-Bold';
                font-size: 13px;
                line-height: 14px;
                letter-spacing: 3.25px;
                text-transform: uppercase;
            }
            .capital-reg-right-img {
                width: 56px;
                height: 54px;
                flex-shrink: 0;
                background: #bfff00;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 20px;
                    height: 519px;
                    display: block;
                }
            }
        }
    }
}
@media (min-width: 1440px) {
    .capital-wrap {
        overflow: hidden;
        width: 100%;
        height: 100%;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
        .capital-header {
            width: 100%;
            height: 437px;
            background: #bfff00;
            .capital-header-head {
                margin-bottom: 61px;
                display: flex;
                align-items: center;
                .capital-header-head-logo {
                    display: block;
                    width: 195px;
                    height: 68px;
                    object-fit: cover;
                    margin-right: 31px;
                }
                .capital-header-head-link {
                    display: flex;
                    align-items: center;
                    font-family: 'Inter-Regular';
                    font-size: 18px;
                    font-style: normal;
                    opacity: 0.4;
                    img {
                        display: block;
                        width: 16px;
                        height: 16px;
                        cursor: pointer;
                        object-fit: cover;
                        margin-left: 7px;
                    }
                }
            }
            .capital-header-middle {
                text-align: center;
                width: 650px;
                height: 138px;
                margin: auto;
                color: #131313;
                font-family: 'Inter-Bold';
                font-size: 65px;
                line-height: 65px;
            }
            .capital-header-bottom {
                text-align: center;
                font-family: 'Inter-Medium';
                font-weight: 500;
                color: #131313;
                font-size: 28px;
                margin-top: 28px;
            }
        }
        .capital-number-info {
            width: 100%;
            height: 437px;
            color: #fff;
            font-family: 'Inter-bold';
            position: relative;
            img {
                width: 100%;
                height: 437px;
                object-fit: cover;
                background-repeat: no-repeat;
            }
            .capital-number-info-desc {
                position: absolute;
                top: 101px;
                left: 50%;
                transform: translate(-50%);
                font-family: 'Inter-SemiBold';
                width: 1042px;
                padding-bottom: 29px;
                border-bottom: 1px solid #c9c7c7;
                margin: auto;
                text-align: center;
                font-size: 18px;
                // padding-top: 101px;
                letter-spacing: 5.4px;
                text-transform: uppercase;
            }
            .capital-number-info-bottom {
                position: absolute;
                top: 205px;
                left: 50%;
                transform: translate(-50%);
                display: flex;
                align-items: center;
                justify-content: center;
                // margin-top: 48px;
                .capital-number-info-desc-number {
                    font-size: 190px;
                    margin-right: 91px;
                    line-height: 100px;
                }
                .capital-number-info-desc-desc {
                    font-size: 42px;
                    width: 486px;
                    height: 147px;
                    text-align: left;
                    line-height: 50px;
                }
            }
        }

        .capital-program {
            width: 100%;
            background: #131313;
            padding: 117px 0 120px 0;
            .capital-program-desc {
                width: 650px;
                height: 185px;
                color: #c9c7c7;
                text-align: center;
                font-family: 'Inter-Light';
                font-weight: 400;
                font-size: 18px;
                margin: auto;
                line-height: 35px;
                letter-spacing: -0.36px;
                margin-bottom: 99px;
            }
            .capital-program-bottom {
                width: 1321px;
                height: 457px;
                margin: auto;
                display: flex;
                align-items: center;
                .capital-program-bottom-left {
                    height: 100%;
                    width: 101px;
                    background: #bfff00;
                    position: relative;
                    .infoText {
                        position: absolute;
                        left: -37px;
                        top: 50%;
                        color: #121212;
                        text-align: center;
                        font-size: 42px;
                        font-family: 'Inter-Bold';
                        font-weight: 700;
                        line-height: 50px;
                        transform: rotate(-90deg);
                    }
                }
                .capital-program-bottom-right {
                    border: 1px solid #808080;
                    height: 100%;
                    .capital-program-bottom-right-li {
                        border-bottom: 1px solid #808080;
                        display: flex;
                        // align-items: center;
                        box-sizing: border-box;
                        height: 152px;
                        // cursor: pointer;
                        .capital-program-bottom-right-li-left {
                            width: 327px;
                            color: #fff;
                            text-align: center;
                            .capital-date {
                                font-size: 28px;
                                font-family: 'Inter-Bold';
                                margin-top: 40px;
                            }
                            .capital-day {
                                font-size: 18px;
                                font-family: 'Inter-Light';
                            }
                        }
                        .capital-program-bottom-right-li-middle {
                            display: block;
                            width: 212px;
                            height: 150px;
                            background: #000;
                            border-left: 1px solid #808080;
                            // border-right: 1px solid #808080;
                        }
                        .capital-program-bottom-right-li-right {
                            width: 681px;
                            line-height: 152px;
                            text-align: left;
                            padding-left: 36px;
                            background: #fff;
                            color: #000;
                            font-family: 'Inter-Regular';
                            font-size: 18px;
                        }
                    }
                    .capital-program-bottom-right-li:hover {
                        .capital-program-bottom-right-li-right {
                            background-color: #f8ffe4;
                        }
                    }
                    .capital-program-bottom-right-li:last-child {
                        border-bottom: 0;
                    }
                }
            }
            .capital-program-button {
                width: 1321px;
                margin: auto;
                display: flex;
                align-items: center;
                direction: rtl;
                cursor: pointer;
                .capital-program-learn {
                    height: 54px;
                    background: #000;
                    border: 1px solid #bfff00;
                    display: flex;
                    align-items: center;
                    .capital-reg-right-title {
                        flex: 1;
                        text-align: center;
                        line-height: 54px;
                        color: #bfff00;
                        font-family: 'Inter-Bold';
                        font-size: 13px;
                        line-height: 14px;
                        letter-spacing: 3.25px;
                        text-transform: uppercase;
                        padding-left: 25px;
                        padding-right: 25px;
                    }
                    .capital-reg-right-img {
                        width: 56px;
                        height: 54px;
                        flex-shrink: 0;
                        background: #bfff00;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 20px;
                            height: 519px;
                            display: block;
                        }
                    }
                }
            }
        }
        .capital-partner {
            width: 100%;
            background: #131313;
            padding: 0 0 120px 0;
            .capital-partner-title {
                font-family: 'Inter-Bold';
                font-size: 42px;
                font-weight: 700;
                line-height: 50px;
                letter-spacing: 0em;
                text-align: center;
                color: #fff;
                margin-bottom: 48px;
            }
            .capital-partner-vertical {
                width: 1px;
                height: 130px;
                margin: auto;
                background: #bfff00;
                margin-bottom: 65px;
            }
            .capital-partner-bottom {
                width: 1321px;
                // height: 457px;
                margin: auto;
                display: flex;
                align-items: center;
                .capital-partner-bottom-left {
                    height: 152px;
                    width: 101px;
                    background: #bfff00;
                }
                .capital-partner-bottom-right {
                    border: 1px solid #808080;
                    // height: 100%;
                    .capital-partner-bottom-right-li {
                        border-bottom: 1px solid #808080;
                        display: flex;
                        // align-items: center;
                        box-sizing: border-box;
                        height: 150px;
                        // cursor: pointer;
                        .capital-partner-bottom-right-li-left {
                            width: 327px;
                            color: #fff;
                            text-align: center;
                            .capital-date {
                                font-size: 28px;
                                font-family: 'Inter-Bold';
                                line-height: 150px;
                            }
                        }
                        .capital-partner-bottom-right-li-middle {
                            display: block;
                            width: 212px;
                            height: 150px;
                            background: #000;
                            border-left: 1px solid #808080;
                            // border-right: 1px solid #808080;
                        }
                        .capital-partner-bottom-right-li-right {
                            width: 681px;
                            line-height: 150px;
                            text-align: left;
                            padding-left: 36px;
                            background: #fff;
                            color: #000;
                            //styleName: P;
                            font-family: 'Inter-Bold';
                            font-size: 18px;
                            font-weight: 400;
                            letter-spacing: -0.02em;
                        }
                    }
                    .capital-partner-bottom-right-li:hover {
                        .capital-program-bottom-right-li-right {
                            background-color: #f8ffe4;
                        }
                    }
                    .capital-partner-bottom-right-li:last-child {
                        border-bottom: 0;
                    }
                }
            }
            .capital-partner-button {
                width: 1321px;
                margin: auto;
                display: flex;
                align-items: center;
                direction: rtl;
                margin-top: -55px;
                padding-right: 1px;
                cursor: pointer;
                .capital-partner-learn {
                    height: 54px;
                    background: #000;
                    border: 1px solid #bfff00;
                    display: flex;
                    align-items: center;
                    .capital-reg-right-title {
                        flex: 1;
                        text-align: center;
                        line-height: 54px;
                        color: #bfff00;
                        font-family: 'Inter-Bold';
                        font-size: 13px;
                        line-height: 14px;
                        letter-spacing: 3.25px;
                        text-transform: uppercase;
                        padding-left: 25px;
                        padding-right: 25px;
                    }
                    .capital-reg-right-img {
                        width: 56px;
                        height: 54px;
                        flex-shrink: 0;
                        background: #bfff00;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 20px;
                            height: 519px;
                            display: block;
                        }
                    }
                }
            }
        }
        .capital-logo-content {
            width: 100%;
            margin: auto;
            background: #131313;
            .capital-logo-title {
                color: #fff;
                text-align: center;
                font-family: 'Inter-Bold';
                font-size: 42px;
                line-height: 50px;
                margin-bottom: 10px;
            }
            .capital-logo-desc {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #c9c7c7;
                font-family: 'Inter-Regular';
                font-size: 18px;
                line-height: 35px;
                letter-spacing: -0.36px;
                margin-bottom: 61px;
                a {
                    color: #bfff00;
                    font-family: 'Inter-SemiBold';
                    line-height: 35px;
                    letter-spacing: -0.36px;
                    text-decoration-line: underline;
                    margin-left: 10px;
                }
            }
            .capital-logo-platinum {
                width: 1321px;
                margin: auto;
                margin-bottom: 66px;
                .capital-platinum-top {
                    width: 100%;
                    height: 110px;
                    flex-shrink: 0;
                    background: linear-gradient(
                        259deg,
                        #dae0e5 13.32%,
                        #f6f9ff 65.06%,
                        #b8c1c9 104.42%
                    );
                    div:nth-child(1) {
                        width: 182px;
                        margin: auto;
                        padding-top: 20px;
                        font-family: 'Inter-Bold';
                        font-size: 42px;
                        line-height: 50px;
                        text-align: center;
                        background: linear-gradient(256deg, #cbd1d6 -6.28%, #c0c5ca 88.7%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                    div:nth-child(2) {
                        color: #c8ccd0;
                        text-align: center;
                        font-family: 'Inter-SemiBold';
                        font-size: 13px;
                        line-height: 35px;
                        letter-spacing: 3.9px;
                        text-transform: uppercase;
                        margin-top: -10px;
                    }
                }
                .capital-platinum-bottom {
                    width: 100%;
                    height: 219px;
                    display: flex;
                    background: #fff;
                    .capital-platinum-bottom-box {
                        width: 25%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-right: 1px solid #c9c7c7;
                        img {
                            width: 180px;
                            height: 74px;
                            flex-shrink: 0;
                            object-fit: contain;
                        }
                    }
                    .capital-platinum-bottom-box:last-child {
                        border-right: none;
                    }
                }
            }
            .capital-logo-gold {
                width: 1321px;
                margin: auto;
                padding-bottom: 62px;
                .capital-gold-top {
                    width: 100%;
                    height: 110px;
                    flex-shrink: 0;
                    background: linear-gradient(252deg, #d7ba93 -5.49%, #c6986c 106.14%);
                    div:nth-child(1) {
                        width: 96px;
                        margin: auto;
                        padding-top: 11px;
                        font-family: 'Inter-Bold';
                        font-size: 42px;
                        line-height: 50px;
                        text-align: center;
                        background: linear-gradient(256deg, #ffeed6 -6.28%, #e4c7a6 88.7%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                    div:nth-child(2) {
                        color: #ebc89e;
                        text-align: center;
                        font-family: 'Inter-SemiBold';
                        font-size: 13px;
                        line-height: 35px;
                        letter-spacing: 3.9px;
                        text-transform: uppercase;
                        margin-top: -10px;
                    }
                }
                .capital-gold-bottom {
                    width: 100%;
                    height: 219px;
                    background: #fff;
                    display: flex;
                    .capital-gold-bottom-box {
                        width: 25%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-right: 1px solid #c9c7c7;
                        img {
                            width: 180px;
                            height: 74px;
                            flex-shrink: 0;
                            object-fit: contain;
                        }
                    }
                    .capital-gold-bottom-box:last-child {
                        border-right: none;
                    }
                }
            }
            .capital-logo-last {
                margin-top: 6px;
                padding-bottom: 120px;
                .capital-logo-last-title {
                    width: 100%;
                    color: #8a8a8a;
                    text-align: center;
                    font-family: 'Inter-Regular';
                    font-size: 18px;
                    line-height: 35px;
                    letter-spacing: 5.4px;
                    text-transform: uppercase;
                    margin-bottom: 11px;
                }
                .capital-logo-last-component {
                    width: 60px;
                    margin: auto;
                }
            }
        }
        .capital-media-partner {
            border-top: 1px solid #8a8a8a;
            border-bottom: 1px solid #8a8a8a;
            background-color: #131313;
            width: 100%;
            display: flex;
            .capital-media-partner-left {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                .capital-media-partner-left-content {
                    width: 482px;
                    // height: 200px;
                    margin: auto;
                    div:nth-child(1) {
                        color: #fff;
                        width: 475px;
                        font-family: 'Inter-Bold';
                        font-size: 42px;
                        font-weight: 700;
                        line-height: 50px;
                        letter-spacing: 0em;
                        margin: auto;
                        padding-bottom: 22px;
                        border-bottom: 1px solid #bfff00;
                        margin-bottom: 66px;
                    }
                    div {
                        margin-top: 39px;
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
            .capital-media-partner-right {
                width: 50%;
                img {
                    width: 100%;
                }
            }
        }
        .capital-registration {
            background: #000000;
            .capital-registration-title {
                text-align: center;
                color: #fff;
                font-family: 'Inter-Bold';
                font-size: 65px;
                line-height: 65px;
                letter-spacing: -1.3px;
                padding-top: 109px;
                margin-bottom: 56px;
            }
            .capital-registration-pass {
                width: 872px;
                padding-bottom: 24px;
                margin: auto;
                border-bottom: 1px solid rgba(201, 199, 199, 0.5);
                color: #fff;
                text-align: center;
                font-family: 'Inter-Light';
                font-size: 18px;
                line-height: 35px;
                letter-spacing: 5.4px;
                text-transform: uppercase;
                margin-top: 70px;
            }
            .capital-registration-head {
                width: 871px;
                height: 186px;
                margin: auto;
                flex-shrink: 0;
                border: 1px solid #54575c;
                background: #131313;
                display: flex;
                justify-content: space-between;
                margin-top: 24px;
                .capital-reg-left {
                    padding-left: 30px;
                    li:nth-child(1) {
                        display: flex;
                        width: 122px;
                        height: 18px;
                        background-color: #c9c7c7;
                        border-radius: 100px;
                        flex-direction: column;
                        justify-content: center;
                        flex-shrink: 0;
                        color: #131313;
                        text-align: center;
                        font-family: 'Inter-SemiBold';
                        font-size: 12px;
                        line-height: 35px;
                        letter-spacing: 2.7px;
                        text-transform: uppercase;
                        margin-top: 31px;
                    }
                    li:nth-child(2) {
                        color: #fff;
                        font-family: 'Inter-Bold';
                        font-size: 42px;
                        line-height: 50px;
                        margin-top: 14px;
                    }
                    li:nth-child(3) {
                        display: flex;
                        align-items: baseline;
                        .common-quan {
                            width: 7px;
                            height: 7px;
                            border-radius: 50%;
                            flex-shrink: 0;
                            background-color: #bfff00;
                            margin-right: 10px;
                        }
                        color: #c9c7c7;
                        font-family: 'Inter-Light';
                        font-size: 18px;
                        line-height: 35px;
                        letter-spacing: -0.36px;
                        margin-top: 12px;
                    }
                }
            }
            .capital-registration-middle {
                width: 1320px;
                height: 1px;
                margin: auto;
                text-align: center;
                border-bottom: 1px solid rgba(201, 199, 199, 0.5);
                color: #fff;
                font-family: 'Inter-Regular';
                font-size: 18px;
                line-height: 35px;
                letter-spacing: 5.4px;
                text-transform: uppercase;
                padding-bottom: 70px;
                padding-top: 59px;
                margin-bottom: 35px;
            }
            .capital-registration-bottom {
                width: 1320px;
                margin: auto;
                display: flex;
                justify-content: space-between;
                padding-bottom: 120px;
                .capital-registration-bottom-ul {
                    width: 427px;
                    height: 594px;
                    flex-shrink: 0;
                    border: 1px solid #54575c;
                    background: #131313;
                    padding-left: 30px;
                    position: relative;
                    li:nth-child(1) {
                        display: flex;
                        width: 200px;
                        height: 20px;
                        border-radius: 100px;
                        align-items: center;
                        margin-left: auto;
                        margin-right: 31px;
                        margin-top: 22px;
                        flex-direction: column;
                        justify-content: center;
                        flex-shrink: 0;
                        color: #131313;
                        text-align: center;
                        font-family: 'Inter-SemiBold';
                        font-size: 12px;
                        line-height: 35px;
                        letter-spacing: 2.7px;
                        text-transform: uppercase;
                    }
                    li:nth-child(2) {
                        margin-top: 33px;
                        color: #fff;
                        font-family: 'Inter-Bold';
                        font-size: 42px;
                        line-height: 50px;
                    }
                    li {
                        display: flex;
                        align-items: baseline;
                        margin-top: 15px;
                        .common-quan {
                            width: 7px;
                            height: 7px;
                            border-radius: 50%;
                            flex-shrink: 0;
                            background-color: #bfff00;
                            margin-right: 10px;
                        }
                        .ul-wenzi {
                            width: 309px;
                            color: #c9c7c7;
                            font-family: 'Inter-Light';
                            font-size: 18px;
                            line-height: 35px;
                            letter-spacing: -0.36px;
                        }
                    }
                    li:last-child {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                    }
                }
            }
            .capital-common-right {
                height: 54px;
                background: #000;
                border: 1px solid #bfff00;
                display: flex;
                align-items: center;
                .capital-reg-right-title {
                    flex: 1;
                    text-align: center;
                    line-height: 54px;
                    color: #bfff00;
                    font-family: 'Inter-Bold';
                    font-size: 13px;
                    line-height: 14px;
                    letter-spacing: 3.25px;
                    text-transform: uppercase;
                }
                .capital-reg-right-img {
                    width: 56px;
                    height: 54px;
                    flex-shrink: 0;
                    background: #bfff00;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 20px;
                        height: 519px;
                        display: block;
                    }
                }
            }
            .w-230 {
                width: 230px;
            }

            .w-220 {
                width: 220px;
            }

            .capital-bottom-liner-left {
                background: linear-gradient(258deg, #5a5e64 -7.1%, #b6becb 56.27%, #565a60 104.48%);
            }

            .capital-bottom-liner-middle {
                background: linear-gradient(268deg, #d6b891 2.25%, #c79b6f 93.38%);
            }

            .capital-bottom-liner-right {
                width: 230px !important;
                background: linear-gradient(
                    259deg,
                    #cdd5db 13.32%,
                    #f6f9ff 65.06%,
                    #b8c1c9 104.42%
                );
            }
        }
        .capital-bottom {
            width: 100%;
            padding: 120px 0;
            background: #bfff00;
            .capital-bottom-title {
                width: 874px;
                margin: auto;
                color: #131313;
                text-align: center;
                font-family: 'Inter-Bold';
                line-height: 65px;
                font-size: 65px;
                letter-spacing: -2%;
                margin-bottom: 22px;
            }
            .capital-bottom-content {
                width: 650px;
                margin: auto;
                color: #131313;
                text-align: center;
                font-family: 'Inter';
                line-height: 35px;
                font-size: 18px;
                font-weight: 400;
                letter-spacing: -2%;
                margin-bottom: 54px;
            }
            .capital-bottom-box {
                width: 874px;
                height: 54px;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                img {
                    display: block;
                    width: 20px;
                    height: 20px;
                }
                .capital-bottom-box-left {
                    width: 234px;
                    display: flex;
                    align-items: center;
                    .capital-bottom-box-left-btn {
                        flex: 1;
                        height: 54px;
                        text-align: center;
                        line-height: 54px;
                        font-size: 13px;
                        background: #8a8a8a;
                        color: #c9c7c7;
                        letter-spacing: 3.25px;
                        text-transform: uppercase;
                        padding-left: 25px;
                        padding-right: 25px;
                    }
                    .capital-bottom-box-left-svg {
                        width: 56px;
                        height: 54px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #c9c7c7;
                    }
                }
                .capital-bottom-box-right {
                    display: flex;
                    align-items: center;
                    background: #131313;
                    // width: 293px;
                    height: 54px;
                    margin-left: 20px;
                    .capital-bottom-box-right-btn {
                        flex: 1;
                        height: 54px;
                        text-align: center;
                        line-height: 54px;
                        font-size: 13px;
                        color: #fff;
                        letter-spacing: 3.25px;
                        text-transform: uppercase;
                        padding-left: 25px;
                        padding-right: 25px;
                    }

                    .capital-bottom-box-right-svg {
                        // width: 50px;
                        // height: 48px;
                        width: 56px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #fff;
                        margin-right: 3px;
                        img {
                            width: 20px;
                            height: 19px;
                            flex-shrink: 0;
                        }
                    }
                }
                .capital-bottom-box-right:hover {
                    .capital-bottom-box-right-btn {
                        color: #bfff00;
                    }

                    .capital-bottom-box-right-svg {
                        background: #bfff00;
                    }
                }
            }
        }
        // modal
        .capital-modal {
            width: 100vw;
            height: 100vh;
            background: #131313;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 9999;
            .capital-modal-box {
                width: 1440px;
                height: 100%;
                margin: auto;
                overflow-y: scroll;
                .capital-modal-head {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 286px;
                    .capital-modal-head-left {
                        width: 723px;
                        height: 100%;
                        border: 1px solid #808080;
                        background: #000;
                        display: flex;
                        flex-direction: column;
                        padding-left: 62px;
                        justify-content: center;
                        .capital-modal-date {
                            color: #fff;
                            font-family: 'Inter-Bold';
                            font-size: 65px;
                            line-height: 65px;
                            letter-spacing: -1.3px;
                        }
                        .capital-modal-day {
                            color: #fff;
                            font-family: 'Inter-Light';
                            font-size: 30px;
                            line-height: 50px;
                            letter-spacing: -0.6px;
                        }
                    }
                    .capital-modal-head-right {
                        flex: 1;
                        height: 100%;
                        position: relative;
                        .capital-modal-head-right-img {
                            width: 100%;
                            height: 100%;
                            display: block;
                            object-fit: cover;
                        }
                        .capital-modal-head-right-close {
                            position: absolute;
                            top: 0;
                            right: 0;
                            display: block;
                            width: 46px;
                            height: 44px;
                            flex-shrink: 0;
                            cursor: pointer;
                            display: block;
                            object-fit: cover;
                        }
                    }
                }
                .point {
                    cursor: pointer;
                }
                .capital-modal-content {
                    display: flex;
                    align-items: stretch;
                    height: 93px;
                    position: relative;

                    .capital-modal-content-absolute {
                        cursor: pointer;
                        position: absolute;
                        right: 0;
                        top: 0;
                    }

                    .capital-modal-content-left {
                        width: 154px;
                        // height:100%;
                        flex-shrink: 0;
                        border-bottom: 1px solid#8A8A8A;
                        background: #bfff00;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        div {
                            color: #131313;
                            font-family: 'Inter-SemiBold';
                            font-size: 18px;
                            line-height: 24px;
                            letter-spacing: -0.44px;
                            text-align: center;
                        }
                    }
                    .capital-modal-content-middle {
                        width: 138px;
                        // height: 100%;
                        display: flex;
                        object-fit: cover;
                        flex-shrink: 0;
                        border-bottom: 1px solid #8a8a8a;
                        justify-content: center;
                        align-items: center;
                        img {
                            width: 100%;
                            height: 91px;
                        }
                    }
                    .capital-modal-content-right {
                        flex: 1;
                        // height: 100%;
                        border-bottom: 1px solid #8a8a8a;
                        background: #fff;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding-left: 27px;
                        div:nth-child(1) {
                            color: #131313;
                            font-family: 'Inter-SemiBold';
                            font-size: 18px;
                            line-height: 28px;
                            letter-spacing: -0.36px;
                        }
                        div:nth-child(2) {
                            color: #8a8a8a;
                            font-family: 'Inter-SemiBold';
                            font-size: 18px;
                            line-height: 28px;
                            letter-spacing: -0.36px;
                            .span-content {
                                span:nth-child(2) {
                                    font-family: 'Inter-Light';
                                }
                                span:nth-child(3) {
                                    font-family: 'Inter-Light';
                                }
                                span:nth-child(4) {
                                    font-family: 'Inter-Light';
                                }
                            }
                        }
                    }
                }
                .special-style {
                    height: 323px !important;
                    img {
                        width: 335px !important;
                        height: 321px !important;
                        flex-shrink: 0;
                    }
                    .capital-modal-content-right {
                        padding-left: 67px;
                    }
                }
                .capital-modal-bottom {
                    width: 100%;
                    height: 93px;
                    display: flex;
                    align-items: center;
                    .capital-modal-btn {
                        display: flex;
                        width: 97px;
                        height: 100%;
                        flex-shrink: 0;
                        border: 1px solid #bfff00;
                        background: #bfff00;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 36px;
                            height: 34px;
                            flex-shrink: 0;
                            cursor: pointer;
                            display: block;
                        }
                    }
                    .capital-modal-bottom-middle {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex: 1;
                        height: 100%;
                        // border: 1px solid #8A8A8A;
                        background: #000;
                        div {
                            width: 50%;
                            height: 100%;
                            color: #fff;
                            text-align: center;
                            font-family: 'Inter-Bold';
                            font-size: 42px;
                            line-height: 93px;
                        }
                        div:nth-child(1) {
                            border-right: 1px solid #8a8a8a;
                        }
                    }
                }
            }
            .bg-gray {
                border-bottom: 1px solid #8a8a8a !important;
                border-right: 1px solid #8a8a8a !important;
                background: #c9c7c7 !important;
            }
        }

        .capital-modal-partner {
            width: 100%;
            height: 100vh;
            background: #8a8a8a66;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 9999;
            .capital-modal-partner-detail {
                width: 1271px;
                height: 100%;
                margin: auto;
                margin-top: 26px;
                position: relative;
                .capital-modal-partner-close {
                    top: 42px;
                    right: 42px;
                    position: absolute;
                    cursor: pointer;
                }
                .capital-modal-partner-table {
                    display: flex;
                    .capital-modal-partner-img {
                        width: 618px;
                        height: 618px;
                    }
                    .capital-modal-partner-content {
                        padding: 42px;
                        background: #000;
                        .capital-modal-partner-title {
                            font-family: 'Inter-Bold';
                            font-size: 42px;
                            font-weight: 700;
                            line-height: 50px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: #fff;
                        }
                        .capital-modal-partner-desc {
                            font-family: 'Inter-Bold';
                            font-size: 18px;
                            font-weight: 600;
                            line-height: 35px;
                            letter-spacing: -0.02em;
                            text-align: left;
                            color: #c9c7c7;
                            margin-top: 25px;
                            a {
                                font-weight: 400;
                                color: #bfff00;
                            }
                        }
                    }
                }
                .capital-modal-partne-button {
                    margin: auto;
                    display: flex;
                    align-items: center;
                    direction: rtl;
                    cursor: pointer;
                    .capital-modal-partner-learn {
                        height: 54px;
                        background: #000;
                        border: 1px solid #bfff00;
                        display: flex;
                        align-items: center;
                        .capital-reg-right-title {
                            flex: 1;
                            text-align: center;
                            line-height: 54px;
                            color: #bfff00;
                            font-family: 'Inter-Bold';
                            font-size: 13px;
                            line-height: 14px;
                            letter-spacing: 3.25px;
                            text-transform: uppercase;
                            padding-left: 25px;
                            padding-right: 25px;
                        }
                        .capital-reg-right-img {
                            width: 56px;
                            height: 54px;
                            flex-shrink: 0;
                            background: #bfff00;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: 20px;
                                height: 519px;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1024px) and (max-width: 1439px) {
    .capital-wrap {
        width: 100%;
        height: 100%;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
        .capital-header {
            width: 100%;
            // height: 437px;
            background: #bfff00;
            .capital-header-head {
                margin-bottom: 60px;
                display: flex;
                align-items: center;
                .capital-header-head-logo {
                    display: block;
                    width: 195px;
                    height: 68px;
                    object-fit: cover;
                    margin-right: 32px;
                }

                .capital-header-head-link {
                    display: flex;
                    align-items: center;
                    font-family: 'Inter-Regular';
                    font-size: 18px;
                    letter-spacing: -0.36px;
                    opacity: 0.4;
                    img {
                        display: block;
                        width: 16px;
                        height: 16px;
                        cursor: pointer;
                        object-fit: cover;
                        margin-left: 7px;
                    }
                }
            }
            .capital-header-middle {
                text-align: center;
                width: 570px;
                height: 138px;
                margin: auto;
                color: #131313;
                font-family: 'Inter-Bold';
                font-size: 50px;
                line-height: 50px;
            }
            .capital-header-bottom {
                text-align: center;
                font-family: 'Inter-Medium';
                color: #131313;
                font-size: 24px;
                padding-bottom: 115px;
            }
        }
        .capital-number-info {
            width: 100%;
            height: 408px;
            color: #fff;
            font-family: 'Inter-bold';
            position: relative;
            img {
                width: 100%;
                height: 408px;
                object-fit: cover;
                background-repeat: no-repeat;
            }
            .capital-number-info-desc {
                position: absolute;
                left: 50%;
                top: 112px;
                transform: translate(-50%);
                font-family: 'Inter-SemiBold';
                width: 832px;
                padding-bottom: 22px;
                border-bottom: 1px solid #c9c7c7;
                margin: auto;
                text-align: center;
                font-size: 16px;
                // padding-top: 112px;
                letter-spacing: 5.4px;
                text-transform: uppercase;
            }

            .capital-number-info-bottom {
                position: absolute;
                left: 50%;
                top: 170px;
                transform: translate(-50%);
                display: flex;
                align-items: center;
                justify-content: center;
                // margin-top: 22px;
                padding-bottom: 97px;
                .capital-number-info-desc-number {
                    font-size: 150px;
                    margin-right: 20px;
                    line-height: 100px;
                }
                .capital-number-info-desc-desc {
                    font-size: 36px;
                    width: 433px;
                    height: 147px;
                    text-align: left;
                    line-height: 50px;
                }
            }
        }
        .join {
            .join-top {
                max-width: 650px;
            }
            .join-img-list {
                gap: 21px;
                div:nth-child(odd) {
                    display: none;
                }
            }
        }
        .capital-program {
            width: 100%;
            background: #131313;
            padding: 55px 0 65px 0;
            .capital-program-desc {
                width: 570px;
                height: 185px;
                color: #c9c7c7;
                text-align: center;
                font-family: 'Inter-Regular';
                font-size: 16px;
                margin: auto;
                line-height: 35px;
                letter-spacing: -0.36px;
                margin-bottom: 50px;
            }
            .capital-program-bottom {
                width: 965px;
                margin: auto;
                .capital-program-bottom-left {
                    width: 100%;
                    height: 59px;
                    background: #bfff00;
                    .infoText {
                        width: 100%;
                        height: 59px;
                        background: #bfff00;
                        color: #121212;
                        text-align: center;
                        font-size: 34px;
                        font-family: 'Inter-Bold';
                        line-height: 59px;
                    }
                }
                .capital-program-bottom-right {
                    border: 1px solid #808080;
                    height: 457px;
                    .capital-program-bottom-right-li {
                        border-bottom: 1px solid #808080;
                        display: flex;
                        // align-items: center;
                        box-sizing: border-box;
                        height: 152px;
                        // cursor: pointer;
                        .capital-program-bottom-right-li-left {
                            width: 221px;
                            color: #fff;
                            text-align: center;
                            .capital-date {
                                font-size: 28px;
                                font-family: 'Inter-Bold';
                                margin-top: 40px;
                            }
                            .capital-day {
                                font-size: 18px;
                                font-family: 'Inter-Light';
                            }
                        }

                        .capital-program-bottom-right-li-middle {
                            display: block;
                            width: 212px;
                            height: 150px;
                            background: #000;
                            border-left: 1px solid #808080;
                            // border-right: 1px solid #808080;
                        }

                        .capital-program-bottom-right-li-right {
                            width: 531px;
                            line-height: 152px;
                            text-align: left;
                            padding-left: 36px;
                            background: #fff;
                            color: #000;
                            font-family: 'Inter-Regular';
                            font-size: 18px;
                        }
                    }
                    .capital-program-bottom-right-li:hover {
                        .capital-program-bottom-right-li-right {
                            background-color: #f8ffe4;
                        }
                    }
                    .capital-program-bottom-right-li:last-child {
                        border-bottom: 0;
                    }
                }
            }
            .capital-program-button {
                width: 965px;
                margin: auto;
                display: flex;
                align-items: center;
                direction: rtl;
                cursor: pointer;
                .capital-program-learn {
                    height: 54px;
                    background: #000;
                    border: 1px solid #bfff00;
                    display: flex;
                    align-items: center;
                    .capital-reg-right-title {
                        flex: 1;
                        text-align: center;
                        line-height: 54px;
                        color: #bfff00;
                        font-family: 'Inter-Bold';
                        font-size: 13px;
                        line-height: 14px;
                        letter-spacing: 3.25px;
                        text-transform: uppercase;
                        padding-left: 25px;
                        padding-right: 25px;
                    }
                    .capital-reg-right-img {
                        width: 56px;
                        height: 54px;
                        flex-shrink: 0;
                        background: #bfff00;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 20px;
                            height: 519px;
                            display: block;
                        }
                    }
                }
            }
        }
        .capital-partner {
            width: 100%;
            background: #131313;
            padding: 0 0 120px 0;
            .capital-partner-title {
                font-family: 'Inter-Bold';
                font-size: 42px;
                font-weight: 700;
                line-height: 50px;
                letter-spacing: 0em;
                text-align: center;
                color: #fff;
                margin-bottom: 48px;
            }
            .capital-partner-vertical {
                width: 1px;
                height: 130px;
                margin: auto;
                background: #bfff00;
                margin-bottom: 65px;
            }
            .capital-partner-bottom {
                width: 965px;
                // height: 457px;
                margin: auto;
                display: flex;
                align-items: center;
                .capital-partner-bottom-left {
                    height: 152px;
                    width: 0px;
                    background: #bfff00;
                }
                .capital-partner-bottom-right {
                    border: 1px solid #808080;
                    // height: 100%;
                    .capital-partner-bottom-right-li {
                        border-bottom: 1px solid #808080;
                        display: flex;
                        // align-items: center;
                        box-sizing: border-box;
                        height: 150px;
                        // cursor: pointer;
                        .capital-partner-bottom-right-li-left {
                            width: 221px;
                            color: #fff;
                            text-align: center;
                            .capital-date {
                                font-size: 28px;
                                font-family: 'Inter-Bold';
                                line-height: 150px;
                            }
                        }
                        .capital-partner-bottom-right-li-middle {
                            display: block;
                            width: 212px;
                            height: 150px;
                            background: #000;
                            border-left: 1px solid #808080;
                            // border-right: 1px solid #808080;
                        }
                        .capital-partner-bottom-right-li-right {
                            width: 531px;
                            line-height: 150px;
                            text-align: left;
                            padding-left: 36px;
                            background: #fff;
                            color: #000;
                            //styleName: P;
                            font-family: 'Inter-Bold';
                            font-size: 18px;
                            font-weight: 400;
                            letter-spacing: -0.02em;
                        }
                    }
                    .capital-partner-bottom-right-li:hover {
                        .capital-program-bottom-right-li-right {
                            background-color: #f8ffe4;
                        }
                    }
                    .capital-partner-bottom-right-li:last-child {
                        border-bottom: 0;
                    }
                }
            }
            .capital-partner-button {
                width: 965px;
                margin: auto;
                display: flex;
                align-items: center;
                direction: rtl;
                cursor: pointer;
                margin-top: -55px;
                padding-right: 1px;
                .capital-partner-learn {
                    height: 54px;
                    background: #000;
                    border: 1px solid #bfff00;
                    display: flex;
                    align-items: center;
                    .capital-reg-right-title {
                        flex: 1;
                        text-align: center;
                        line-height: 54px;
                        color: #bfff00;
                        font-family: 'Inter-Bold';
                        font-size: 13px;
                        line-height: 14px;
                        letter-spacing: 3.25px;
                        text-transform: uppercase;
                        padding-left: 25px;
                        padding-right: 25px;
                    }
                    .capital-reg-right-img {
                        width: 56px;
                        height: 54px;
                        flex-shrink: 0;
                        background: #bfff00;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 20px;
                            height: 519px;
                            display: block;
                        }
                    }
                }
            }
        }
        .capital-logo-content {
            width: 100%;
            margin: auto;
            background: #131313;
            .capital-logo-title {
                color: #fff;
                text-align: center;
                font-family: 'Inter-Bold';
                font-size: 42px;
                line-height: 50px;
                margin-bottom: 10px;
            }
            .capital-logo-desc {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #c9c7c7;
                font-family: 'Inter-Regular';
                font-size: 18px;
                line-height: 35px;
                letter-spacing: -0.36px;
                margin-bottom: 61px;
                a {
                    color: #bfff00;
                    font-family: 'Inter-SemiBold';
                    line-height: 35px;
                    letter-spacing: -0.36px;
                    text-decoration-line: underline;
                    margin-left: 10px;
                }
            }
            .capital-logo-platinum {
                width: 100%;
                padding: 0 30px;
                margin-bottom: 65px;
                .capital-platinum-top {
                    width: 100%;
                    height: 110px;
                    flex-shrink: 0;
                    background: linear-gradient(
                        259deg,
                        #dae0e5 13.32%,
                        #f6f9ff 65.06%,
                        #b8c1c9 104.42%
                    );
                    div:nth-child(1) {
                        width: 182px;
                        margin: auto;
                        padding-top: 20px;
                        font-family: 'Inter-Bold';
                        font-size: 42px;
                        line-height: 50px;
                        text-align: center;
                        background: linear-gradient(256deg, #cbd1d6 -6.28%, #c0c5ca 88.7%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                    div:nth-child(2) {
                        color: #c8ccd0;
                        text-align: center;
                        font-family: 'Inter-SemiBold';
                        font-size: 13px;
                        line-height: 35px;
                        letter-spacing: 3.9px;
                        text-transform: uppercase;
                        margin-top: -10px;
                    }
                }

                .capital-platinum-bottom {
                    width: 100%;
                    height: 219px;
                    display: flex;
                    background: #fff;

                    .capital-platinum-bottom-box {
                        width: 25%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-right: 1px solid #c9c7c7;
                        img {
                            width: 180px;
                            height: 74px;
                            flex-shrink: 0;
                            object-fit: contain;
                        }
                    }

                    .capital-platinum-bottom-box:last-child {
                        border-right: none;
                    }
                }
            }
            .capital-logo-gold {
                width: 100%;
                padding: 0 30px;
                padding-bottom: 62px;
                .capital-gold-top {
                    width: 100%;
                    height: 110px;
                    flex-shrink: 0;
                    background: linear-gradient(252deg, #d7ba93 -5.49%, #c6986c 106.14%);
                    div:nth-child(1) {
                        width: 96px;
                        margin: auto;
                        padding-top: 11px;
                        font-family: 'Inter-Bold';
                        font-size: 42px;
                        line-height: 50px;
                        text-align: center;
                        background: linear-gradient(256deg, #ffeed6 -6.28%, #e4c7a6 88.7%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                    div:nth-child(2) {
                        color: #ebc89e;
                        text-align: center;
                        font-family: 'Inter-SemiBold';
                        font-size: 13px;
                        line-height: 35px;
                        letter-spacing: 3.9px;
                        text-transform: uppercase;
                        margin-top: -10px;
                    }
                }
                .capital-gold-bottom {
                    width: 100%;
                    height: 219px;
                    display: flex;
                    background: #fff;

                    .capital-gold-bottom-box {
                        width: 25%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-right: 1px solid #c9c7c7;

                        img {
                            width: 180px;
                            height: 74px;
                            flex-shrink: 0;
                            object-fit: contain;
                        }
                    }

                    .capital-gold-bottom-box:last-child {
                        border-right: none;
                    }
                }
            }
            .capital-logo-middle {
                padding-bottom: 91px;
            }
        }
        .capital-bottom {
            width: 100%;
            padding: 108px 0;
            background: #bfff00;
            .capital-bottom-title {
                width: 965px;
                height: 83px;
                margin: auto;
                color: #131313;
                text-align: center;
                font-family: 'Inter-Bold';
                line-height: 65px;
                font-size: 50px;
                letter-spacing: -1.3px;
            }
            .capital-bottom-content {
                width: 965px;
                margin: auto;
                color: #131313;
                text-align: center;
                font-family: 'Inter';
                line-height: 35px;
                font-size: 18px;
                font-weight: 400;
                letter-spacing: -2%;
                margin-bottom: 54px;
            }
            .capital-bottom-box {
                height: 54px;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                img {
                    display: block;
                    width: 20px;
                    height: 20px;
                }
                .capital-bottom-box-left {
                    display: flex;
                    align-items: center;
                    // width: 234px;
                    .capital-bottom-box-left-btn {
                        flex: 1;
                        height: 54px;
                        font-size: 13px;
                        text-align: center;
                        line-height: 54px;
                        background: #8a8a8a;
                        color: #c9c7c7;
                        letter-spacing: 3.25px;
                        text-transform: uppercase;
                        padding-left: 25px;
                        padding-right: 25px;
                    }
                    .capital-bottom-box-left-svg {
                        width: 56px;
                        height: 54px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #c9c7c7;
                    }
                }
                .capital-bottom-box-right {
                    display: flex;
                    align-items: center;
                    background: #131313;
                    // width: 293px;
                    height: 54px;
                    margin-left: 20px;

                    .capital-bottom-box-right-btn {
                        flex: 1;
                        height: 54px;
                        font-size: 13px;
                        text-align: center;
                        line-height: 54px;
                        color: #fff;
                        letter-spacing: 3.25px;
                        text-transform: uppercase;
                        padding-left: 25px;
                        padding-right: 25px;
                    }

                    .capital-bottom-box-right-svg {
                        width: 50px;
                        height: 48px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #fff;
                        margin-right: 3px;
                        img {
                            width: 20px;
                            height: 19px;
                            flex-shrink: 0;
                        }
                    }
                }
                .capital-bottom-box-right:hover {
                    .capital-bottom-box-right-btn {
                        color: #bfff00;
                    }
                    .capital-bottom-box-right-svg {
                        background: #bfff00;
                    }
                }
            }
        }
        .capital-registration {
            background: #000000;
            .capital-registration-title {
                text-align: center;
                color: #fff;
                font-family: 'Inter-Bold';
                font-size: 65px;
                line-height: 65px;
                letter-spacing: -1.3px;
                padding-top: 109px;
            }
            .capital-registration-pass {
                width: 965px;
                padding-bottom: 24px;
                margin: auto;
                border-bottom: 1px solid rgba(201, 199, 199, 0.5);
                color: #fff;
                text-align: center;
                font-family: 'Inter-Light';
                font-size: 18px;
                line-height: 35px;
                letter-spacing: 5.4px;
                text-transform: uppercase;
                margin-top: 70px;
            }
            .capital-registration-head {
                width: 963px;
                height: 186px;
                margin: auto;
                flex-shrink: 0;
                border: 1px solid #54575c;
                background: #131313;
                display: flex;
                justify-content: space-between;
                margin-top: 24px;

                .capital-reg-left {
                    padding-left: 30px;
                    li:nth-child(1) {
                        display: flex;
                        width: 122px;
                        height: 18px;
                        background-color: #c9c7c7;
                        border-radius: 100px;
                        flex-direction: column;
                        justify-content: center;
                        flex-shrink: 0;
                        color: #131313;
                        text-align: center;
                        font-family: 'Inter-SemiBold';
                        font-size: 12px;
                        line-height: 35px;
                        letter-spacing: 2.7px;
                        text-transform: uppercase;
                        margin-top: 31px;
                    }

                    li:nth-child(2) {
                        color: #fff;
                        font-family: 'Inter-Bold';
                        font-size: 42px;
                        line-height: 50px;
                        margin-top: 14px;
                    }

                    li:nth-child(3) {
                        display: flex;
                        align-items: baseline;
                        .common-quan {
                            width: 7px;
                            height: 7px;
                            border-radius: 50%;
                            flex-shrink: 0;
                            background-color: #bfff00;
                            margin-right: 10px;
                        }
                        color: #c9c7c7;
                        font-family: 'Inter-Light';
                        font-size: 18px;
                        line-height: 35px;
                        letter-spacing: -0.36px;
                        margin-top: 12px;
                    }
                }
            }
            .capital-registration-middle {
                width: 964px;
                height: 1px;
                margin: auto;
                text-align: center;
                border-bottom: 1px solid rgba(201, 199, 199, 0.5);
                color: #fff;
                font-family: 'Inter-Regular';
                font-size: 18px;
                line-height: 35px;
                letter-spacing: 5.4px;
                text-transform: uppercase;
                padding-bottom: 70px;
                padding-top: 59px;
                margin-bottom: 35px;
            }

            .capital-registration-bottom {
                width: 964px;
                margin: auto;
                display: flex;
                justify-content: space-between;
                padding-bottom: 120px;

                .capital-registration-bottom-ul {
                    width: 308px;
                    height: 594px;
                    flex-shrink: 0;
                    border: 1px solid #54575c;
                    background: #131313;
                    padding-left: 30px;
                    position: relative;

                    li:nth-child(1) {
                        display: flex;
                        width: 200px;
                        height: 20px;
                        border-radius: 100px;
                        align-items: center;
                        margin-left: auto;
                        margin-right: 31px;
                        margin-top: 22px;
                        flex-direction: column;
                        justify-content: center;
                        flex-shrink: 0;
                        color: #131313;
                        text-align: center;
                        font-family: 'Inter-SemiBold';
                        font-size: 12px;
                        line-height: 35px;
                        letter-spacing: 2.7px;
                        text-transform: uppercase;
                    }

                    li:nth-child(2) {
                        margin-top: 33px;
                        color: #fff;
                        font-family: 'Inter-Bold';
                        font-size: 42px;
                        line-height: 50px;
                    }

                    li {
                        display: flex;
                        align-items: baseline;
                        margin-top: 15px;

                        .common-quan {
                            width: 7px;
                            height: 7px;
                            border-radius: 50%;
                            flex-shrink: 0;
                            background-color: #bfff00;
                            margin-right: 10px;
                        }

                        .ul-wenzi {
                            width: 309px;
                            color: #c9c7c7;
                            font-family: 'Inter-Light';
                            font-size: 18px;
                            line-height: 35px;
                            letter-spacing: -0.36px;
                        }
                    }

                    li:last-child {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                    }
                }
            }

            .capital-common-right {
                height: 54px;
                background: #000;
                border: 1px solid #bfff00;
                display: flex;
                align-items: center;

                .capital-reg-right-title {
                    flex: 1;
                    text-align: center;
                    line-height: 54px;
                    color: #bfff00;
                    font-family: 'Inter-Bold';
                    font-size: 13px;
                    line-height: 14px;
                    letter-spacing: 3.25px;
                    text-transform: uppercase;
                }

                .capital-reg-right-img {
                    width: 56px;
                    height: 54px;
                    flex-shrink: 0;
                    background: #bfff00;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 20px;
                        height: 519px;
                        display: block;
                    }
                }
            }

            .w-230 {
                width: 230px;
            }

            .w-220 {
                width: 220px;
            }

            .capital-bottom-liner-left {
                background: linear-gradient(258deg, #5a5e64 -7.1%, #b6becb 56.27%, #565a60 104.48%);
            }

            .capital-bottom-liner-middle {
                background: linear-gradient(268deg, #d6b891 2.25%, #c79b6f 93.38%);
            }

            .capital-bottom-liner-right {
                width: 230px !important;
                background: linear-gradient(
                    259deg,
                    #cdd5db 13.32%,
                    #f6f9ff 65.06%,
                    #b8c1c9 104.42%
                );
            }
        }
        .capital-media-partner {
            border-top: 1px solid #8a8a8a;
            border-bottom: 1px solid #8a8a8a;
            background-color: #131313;
            width: 100%;
            display: flex;
            .capital-media-partner-left {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                .capital-media-partner-left-content {
                    width: 482px;
                    // height: 200px;
                    margin: auto;
                    div:nth-child(1) {
                        color: #fff;
                        width: 475px;
                        font-family: 'Inter-Bold';
                        font-weight: 700;
                        font-size: 42px;
                        line-height: 50px;
                        margin: auto;
                        padding-bottom: 22px;
                        border-bottom: 1px solid #bfff00;
                        margin-bottom: 66px;
                    }
                    div {
                        margin-top: 39px;
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
            .capital-media-partner-right {
                width: 50%;
                img {
                    width: 100%;
                }
            }
        }
        // modal
        .capital-modal {
            width: 100%;
            height: 100%;
            background: #131313;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 9999;
            .capital-modal-box {
                width: 100%;
                height: 100%;
                margin: auto;
                overflow-y: scroll;
                .capital-modal-head {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 203px;
                    .capital-modal-head-left {
                        width: 50%;
                        height: 100%;
                        border: 1px solid #808080;
                        background: #000;
                        display: flex;
                        flex-direction: column;
                        padding-left: 62px;
                        justify-content: center;

                        .capital-modal-date {
                            color: #fff;
                            font-family: 'Inter-Bold';
                            font-size: 65px;
                            line-height: 65px;
                            letter-spacing: -1.3px;
                        }

                        .capital-modal-day {
                            color: #fff;
                            font-family: 'Inter-Light';
                            font-size: 30px;
                            line-height: 50px;
                            letter-spacing: -0.6px;
                        }
                    }

                    .capital-modal-head-right {
                        flex: 1;
                        height: 100%;
                        position: relative;

                        .capital-modal-head-right-img {
                            width: 100%;
                            height: 100%;
                            display: block;
                            object-fit: cover;
                        }

                        .capital-modal-head-right-close {
                            position: fixed;
                            top: 0;
                            right: 0;
                            display: block;
                            width: 46px;
                            height: 44px;
                            flex-shrink: 0;
                            cursor: pointer;
                            display: block;
                            object-fit: cover;
                        }
                    }
                }
                .point {
                    cursor: pointer;
                }
                .capital-modal-content {
                    display: flex;
                    align-items: stretch;
                    min-height: 93px;
                    position: relative;

                    .capital-modal-content-absolute {
                        cursor: pointer;
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                    .capital-modal-content-left {
                        width: 154px;
                        // height: 100%;
                        flex-shrink: 0;
                        border-bottom: 1px solid#8A8A8A;
                        background: #bfff00;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        div {
                            color: #131313;
                            font-family: 'Inter-SemiBold';
                            font-size: 18px;
                            line-height: 24px;
                            letter-spacing: -0.44px;
                            text-align: center;
                        }
                    }

                    .capital-modal-content-middle {
                        width: 138px;
                        // height: 100%;
                        display: flex;
                        object-fit: cover;
                        // flex-shrink: 0;
                        border-bottom: 1px solid #8a8a8a;
                        justify-content: center;
                        align-items: center;
                        img {
                            width: 100%;
                            height: 91px;
                        }
                    }

                    .capital-modal-content-right {
                        flex: 1;
                        // height: 100%;
                        border-bottom: 1px solid #8a8a8a;
                        background: #fff;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding-left: 27px;

                        div:nth-child(1) {
                            color: #131313;
                            font-family: 'Inter-SemiBold';
                            font-size: 18px;
                            line-height: 30px;
                            letter-spacing: -0.36px;
                        }

                        div:nth-child(2) {
                            color: #8a8a8a;
                            font-family: 'Inter-SemiBold';
                            font-size: 18px;
                            line-height: 30px;
                            letter-spacing: -0.36px;
                            .span-content {
                                span:nth-child(2) {
                                    font-family: 'Inter-Light';
                                }
                                span:nth-child(3) {
                                    font-family: 'Inter-Light';
                                }
                                span:nth-child(4) {
                                    font-family: 'Inter-Light';
                                }
                            }
                        }
                    }
                }
                .capital-modal-bottom {
                    width: 100%;
                    height: 68px;
                    display: flex;
                    align-items: center;
                    .capital-modal-btn {
                        display: flex;
                        width: 69px;
                        height: 100%;
                        flex-shrink: 0;
                        border: 1px solid #bfff00;
                        background: #bfff00;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 26px;
                            height: 24px;
                            flex-shrink: 0;
                            cursor: pointer;
                            display: block;
                        }
                    }
                    .capital-modal-bottom-middle {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex: 1;
                        height: 100%;
                        // border: 1px solid #8A8A8A;
                        background: #000;
                        div {
                            width: 50%;
                            height: 100%;
                            color: #fff;
                            text-align: center;
                            font-family: 'Inter-Bold';
                            font-size: 34px;
                            line-height: 67px;
                        }
                        div:nth-child(1) {
                            border-right: 1px solid #8a8a8a;
                        }
                    }
                }
            }

            .bg-gray {
                border-bottom: 1px solid #8a8a8a !important;
                border-right: 1px solid #8a8a8a !important;
                background: #c9c7c7 !important;
            }
        }

        .capital-modal-partner {
            width: 100%;
            height: 100vh;
            background: #8a8a8a66;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 9999;
            .capital-modal-partner-detail {
                width: 960px;
                height: 100%;
                margin: auto;
                margin-top: 26px;
                overflow-y: scroll;
                position: relative;
                .capital-modal-partner-close {
                    top: 20px;
                    right: 20px;
                    position: absolute;
                    cursor: pointer;
                }
                .capital-modal-partner-img {
                    width: 960px;
                    height: 547px;
                }
                .capital-modal-partner-content {
                    padding: 42px;
                    background: #000;
                    .capital-modal-partner-title {
                        font-family: 'Inter-Bold';
                        font-size: 42px;
                        font-weight: 700;
                        line-height: 50px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #fff;
                    }
                    .capital-modal-partner-desc {
                        font-family: 'Inter-Bold';
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 35px;
                        letter-spacing: -0.02em;
                        text-align: left;
                        color: #c9c7c7;
                        margin-top: 25px;
                        a {
                            font-weight: 400;
                            color: #bfff00;
                        }
                    }
                }
                .capital-modal-partne-button {
                    margin: auto;
                    display: flex;
                    align-items: center;
                    direction: rtl;
                    cursor: pointer;
                    .capital-modal-partner-learn {
                        height: 54px;
                        background: #000;
                        border: 1px solid #bfff00;
                        display: flex;
                        align-items: center;
                        .capital-reg-right-title {
                            flex: 1;
                            text-align: center;
                            line-height: 54px;
                            color: #bfff00;
                            font-family: 'Inter-Bold';
                            font-size: 13px;
                            line-height: 14px;
                            letter-spacing: 3.25px;
                            text-transform: uppercase;
                            padding-left: 25px;
                            padding-right: 25px;
                        }
                        .capital-reg-right-img {
                            width: 56px;
                            height: 54px;
                            flex-shrink: 0;
                            background: #bfff00;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: 20px;
                                height: 519px;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 1023px) {
    .capital-wrap {
        width: 100%;
        height: 100%;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
        .capital-header {
            width: 100%;
            // height: 437px;
            background: #bfff00;
            .capital-header-head {
                margin-bottom: 61px;
                display: flex;
                align-items: center;
                .capital-header-head-logo {
                    display: block;
                    width: 148px;
                    height: 51px;
                    object-fit: cover;
                }
                .capital-header-head-link {
                    margin-left: auto;
                    display: flex;
                    align-items: center;
                    font-family: 'Inter-Regular';
                    font-size: 14px;
                    margin-right: 21px;
                    opacity: 0.4;
                    img {
                        display: block;
                        width: 16px;
                        height: 16px;
                        cursor: pointer;
                        object-fit: cover;
                        margin-left: 7px;
                    }
                }
            }
            .capital-header-middle {
                text-align: center;
                width: 350px;
                margin: auto;
                color: #131313;
                font-family: 'Inter-Bold';
                font-size: 50px;
                line-height: 65px;
            }
            .capital-header-bottom {
                text-align: center;
                font-family: 'Inter-Medium';
                color: #131313;
                font-size: 18px;
                width: 200px;
                margin: auto;
                margin-top: 28px;
                padding-bottom: 74px;
            }
        }
        .capital-number-info {
            width: 100%;
            height: 524px;
            color: #fff;
            font-family: 'Inter-bold';
            position: relative;
            background: linear-gradient(
                90deg,
                rgba(0, 0, 0, 0.7) 0%,
                rgba(13, 13, 13, 0.7) 70.83%,
                rgba(18, 18, 18, 0) 100%
            );
            img {
                width: 100%;
                height: 524px;
                object-fit: cover;
                background-repeat: no-repeat;
            }
            .capital-number-info-desc {
                position: absolute;
                left: 50%;
                top: 40px;
                transform: translate(-50%);
                width: 264px;
                margin: auto;
                font-family: 'Inter-SemiBold';
                text-align: center;
                font-size: 12px;
                // padding-top: 33px;
                padding-bottom: 20px;
                border-bottom: 1px solid #c9c7c7;
                letter-spacing: 3.6px;
                text-transform: uppercase;
            }

            .capital-number-info-bottom {
                position: absolute;
                left: 50%;
                top: 142px;
                transform: translate(-50%);
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                // margin-top: 65px;
                .capital-number-info-desc-number {
                    font-size: 130px;
                    line-height: 100px;
                    letter-spacing: -2.6px;
                }
                .capital-number-info-desc-desc {
                    width: 350px;
                    font-size: 32px;
                    line-height: 50px;
                    text-align: center;
                    margin: auto;
                    margin-top: 44px;
                    // padding-bottom: 120px;
                }
            }
        }
        .join {
            .join-top {
                max-width: 650px;
            }
            .join-swiper {
                .my-dots {
                    max-width: calc(100% - 40px);
                }
                .swiper-slide-join {
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    min-height: 400px;
                }
            }
            .join-img-list {
                &::after,
                &::before {
                    display: none;
                }
                flex-direction: column;
                gap: 20px;
                width: calc(100% - 40px);
                div {
                    width: 100%;
                }
                div:first-child {
                    display: none;
                }
            }
        }
        .capital-program {
            width: 100%;
            background: #131313;
            padding: 51px 20px 65px 20px;
            .capital-program-desc {
                color: #c9c7c7;
                text-align: center;
                font-family: 'Inter-Light';
                font-size: 16px;
                margin: auto;
                line-height: 35px;
                letter-spacing: -0.32px;
                margin-bottom: 55px;
            }
            .capital-program-bottom-phone {
                .capital-program-bottom-phone-title {
                    width: 100%px;
                    height: 59px;
                    background: #bfff00;
                    color: #121212;
                    text-align: center;
                    font-size: 34px;
                    font-family: 'Inter-Bold';
                    line-height: 59px;
                }
                .capital-program-bottom-phone-box {
                    .capital-phone-box-li {
                        border: 1px solid #808080;
                        box-sizing: border-box;
                        .capital-phone-box-li-top {
                            width: 100%;
                            height: 151px;
                            color: #fff;
                            text-align: center;
                            background-size: cover;
                            background-repeat: no-repeat;
                            position: relative;
                            // background-color: rgba(0, 0, 0, 0.5);
                            // background:100% 100% no-repeat,lightgray 50% / cover no-repeat;
                            .capital-phone-box-li-top-date {
                                // padding-top: 40px;
                                font-size: 28px;
                                font-family: 'Inter-Bold';
                                position: absolute;
                                left: 50%;
                                top: 40%;
                                transform: translate(-50%, -40%);
                            }
                            .capital-phone-box-li-top-day {
                                font-size: 18px;
                                font-family: 'Inter-Light';
                                position: absolute;
                                left: 50%;
                                top: 70%;
                                transform: translate(-50%, -70%);
                            }
                        }
                        .capital-phone-box-li-top::before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0, 0, 0, 0.5);
                            /*半透明黑色*/
                        }
                        .capital-phone-box-li-bottom {
                            // width: 350px;
                            // height: 151px;
                            background: #fff;
                            color: #000;
                            font-family: 'Inter-Regular';
                            font-size: 18px;
                            letter-spacing: 1.36px;
                            padding: 43px calc((100% - 294px) / 2);
                            line-height: 35px;
                            text-align: center;
                        }
                        .special-pd {
                            padding: 43px 46px;
                        }
                    }
                }
            }
            .capital-program-button {
                // width: 1321px;
                margin: auto;
                display: flex;
                align-items: center;
                direction: rtl;
                cursor: pointer;
                .capital-program-learn {
                    height: 54px;
                    background: #000;
                    border: 1px solid #bfff00;
                    display: flex;
                    align-items: center;
                    .capital-reg-right-title {
                        flex: 1;
                        text-align: center;
                        line-height: 54px;
                        color: #bfff00;
                        font-family: 'Inter-Bold';
                        font-size: 13px;
                        line-height: 14px;
                        letter-spacing: 3.25px;
                        text-transform: uppercase;
                        padding-left: 25px;
                        padding-right: 25px;
                    }
                    .capital-reg-right-img {
                        width: 56px;
                        height: 54px;
                        flex-shrink: 0;
                        background: #bfff00;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 20px;
                            height: 519px;
                            display: block;
                        }
                    }
                }
            }
        }
        .capital-partner {
            width: 100%;
            background: #131313;
            padding: 51px 20px 65px 20px;
            .capital-partner-vertical {
                width: 1px;
                height: 130px;
                margin: auto;
                background: #bfff00;
                margin-bottom: 65px;
            }
            .capital-partner-desc {
                color: #c9c7c7;
                text-align: center;
                font-family: 'Inter-Light';
                font-size: 16px;
                margin: auto;
                line-height: 35px;
                letter-spacing: -0.32px;
                margin-bottom: 55px;
            }
            .capital-partner-bottom-phone {
                .capital-partner-bottom-phone-title {
                    width: 100%px;
                    height: 59px;
                    background: #bfff00;
                    color: #121212;
                    text-align: center;
                    font-size: 34px;
                    font-family: 'Inter-Bold';
                    line-height: 59px;
                }
                .capital-partner-bottom-phone-box {
                    .capital-phone-box-li {
                        border: 1px solid #808080;
                        box-sizing: border-box;
                        .capital-phone-box-li-top {
                            width: 100%;
                            height: 151px;
                            color: #fff;
                            text-align: center;
                            background-size: cover;
                            background-repeat: no-repeat;
                            position: relative;
                            // background-color: rgba(0, 0, 0, 0.5);
                            // background:100% 100% no-repeat,lightgray 50% / cover no-repeat;
                            .capital-phone-box-li-top-date {
                                // padding-top: 40px;
                                font-size: 28px;
                                font-family: 'Inter-Bold';
                                position: absolute;
                                left: 50%;
                                top: 40%;
                                transform: translate(-50%, -40%);
                            }
                            .capital-phone-box-li-top-day {
                                font-size: 18px;
                                font-family: 'Inter-Light';
                                position: absolute;
                                left: 50%;
                                top: 70%;
                                transform: translate(-50%, -70%);
                            }
                        }
                        .capital-phone-box-li-bottom {
                            // width: 350px;
                            // height: 151px;
                            background: #fff;
                            color: #000;
                            font-family: 'Inter-Regular';
                            font-size: 18px;
                            letter-spacing: 1.36px;
                            padding: 43px calc((100% - 294px) / 2);
                            line-height: 35px;
                            text-align: center;
                        }
                        .special-pd {
                            padding: 43px 46px;
                        }
                    }
                }
            }
            .capital-partner-button {
                // width: 1321px;
                margin: auto;
                display: flex;
                align-items: center;
                direction: rtl;
                cursor: pointer;
                .capital-partner-learn {
                    height: 54px;
                    background: #000;
                    border: 1px solid #bfff00;
                    display: flex;
                    align-items: center;
                    .capital-reg-right-title {
                        flex: 1;
                        text-align: center;
                        line-height: 54px;
                        color: #bfff00;
                        font-family: 'Inter-Bold';
                        font-size: 13px;
                        line-height: 14px;
                        letter-spacing: 3.25px;
                        text-transform: uppercase;
                        padding-left: 25px;
                        padding-right: 25px;
                    }
                    .capital-reg-right-img {
                        width: 56px;
                        height: 54px;
                        flex-shrink: 0;
                        background: #bfff00;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 20px;
                            height: 519px;
                            display: block;
                        }
                    }
                }
            }
        }
        .capital-logo-content {
            width: 100%;
            margin: auto;
            background: #131313;
            .capital-logo-title {
                color: #fff;
                text-align: center;
                font-family: 'Inter-Bold';
                font-size: 42px;
                line-height: 50px;
                margin-bottom: 10px;
            }

            .capital-logo-desc {
                width: 100%;
                padding: 0 21px;
                text-align: center;
                color: #c9c7c7;
                font-family: 'Inter-Regular';
                font-size: 16px;
                line-height: 35px;
                letter-spacing: -0.36px;
                margin-bottom: 27px;
                a {
                    color: #bfff00;
                    font-family: 'Inter-SemiBold';
                    line-height: 35px;
                    letter-spacing: -0.36px;
                    text-decoration-line: underline;
                }
            }
            .capital-logo-platinum {
                width: 100%;
                padding: 0 21px;
                .capital-platinum-top {
                    width: 100%;
                    height: 110px;
                    flex-shrink: 0;
                    background: linear-gradient(
                        259deg,
                        #dae0e5 13.32%,
                        #f6f9ff 65.06%,
                        #b8c1c9 104.42%
                    );
                    div:nth-child(1) {
                        width: 182px;
                        margin: auto;
                        padding-top: 20px;
                        font-family: 'Inter-Bold';
                        font-size: 42px;
                        line-height: 50px;
                        text-align: center;
                        background: linear-gradient(256deg, #cbd1d6 -6.28%, #c0c5ca 88.7%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                    div:nth-child(2) {
                        color: #c8ccd0;
                        text-align: center;
                        font-family: 'Inter-SemiBold';
                        font-size: 13px;
                        line-height: 35px;
                        letter-spacing: 3.9px;
                        text-transform: uppercase;
                        margin-top: -10px;
                    }
                }
                .capital-platinum-bottom {
                    width: 100%;
                    background: #fff;
                    .capital-platinum-bottom-box {
                        width: 100%;
                        height: 203px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-bottom: 1px solid #c9c7c7;
                        img {
                            width: 180px;
                            height: 74px;
                            flex-shrink: 0;
                            object-fit: contain;
                        }
                    }
                    .capital-platinum-bottom-box:last-child {
                        border-bottom: none;
                    }
                }
            }

            .capital-logo-gold {
                width: 100%;
                padding: 0 21px;
                padding-bottom: 65px;
                .capital-gold-top {
                    width: 100%;
                    height: 110px;
                    flex-shrink: 0;
                    background: linear-gradient(252deg, #d7ba93 -5.49%, #c6986c 106.14%);
                    div:nth-child(1) {
                        width: 96px;
                        margin: auto;
                        padding-top: 11px;
                        font-family: 'Inter-Bold';
                        font-size: 42px;
                        line-height: 50px;
                        text-align: center;
                        background: linear-gradient(256deg, #ffeed6 -6.28%, #e4c7a6 88.7%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }

                    div:nth-child(2) {
                        color: #ebc89e;
                        text-align: center;
                        font-family: 'Inter-SemiBold';
                        font-size: 13px;
                        line-height: 35px;
                        letter-spacing: 3.9px;
                        text-transform: uppercase;
                        margin-top: -10px;
                    }
                }

                .capital-gold-bottom {
                    width: 100%;
                    background: #fff;
                    .capital-gold-bottom-box {
                        width: 100%;
                        height: 203px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-bottom: 1px solid #c9c7c7;
                        img {
                            width: 180px;
                            height: 74px;
                            flex-shrink: 0;
                            object-fit: contain;
                        }
                    }
                    .capital-gold-bottom-box:last-child {
                        border-bottom: none;
                    }
                }
            }

            .capital-logo-middle {
                padding-bottom: 91px;
            }
        }
        .capital-bottom {
            width: 100%;
            padding: 65px 0;
            background: #bfff00;
            overflow: hidden;
            .capital-bottom-title {
                width: 350px;
                margin: auto;
                color: #131313;
                text-align: center;
                font-family: 'Inter-Bold';
                line-height: 55px;
                font-size: 50px;
                letter-spacing: -1.3px;
                margin-bottom: 32px;
            }
            .capital-bottom-content {
                width: 350px;
                margin: auto;
                color: #131313;
                text-align: center;
                font-family: 'Inter';
                line-height: 35px;
                font-size: 18px;
                font-weight: 400;
                letter-spacing: -2%;
                padding-bottom: 31px;
            }
            .capital-bottom-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                img {
                    display: block;
                    width: 20px;
                    height: 20px;
                }
                .capital-bottom-box-left {
                    display: flex;
                    align-items: center;
                    // width: 234px;
                    height: 54px;
                    margin: auto;
                    .capital-bottom-box-left-btn {
                        flex: 1;
                        text-align: center;
                        line-height: 54px;
                        background: #8a8a8a;
                        color: #c9c7c7;
                        letter-spacing: 3.25px;
                        text-transform: uppercase;
                        padding-left: 25px;
                        padding-right: 25px;
                    }
                    .capital-bottom-box-left-svg {
                        width: 56px;
                        height: 54px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #c9c7c7;
                    }
                }
                .capital-bottom-box-right {
                    display: flex;
                    align-items: center;
                    background: #131313;
                    // width: 293px;
                    margin: auto;
                    margin-top: 21px;
                    .capital-bottom-box-right-btn {
                        flex: 1;
                        height: 54px;
                        text-align: center;
                        line-height: 54px;
                        color: #fff;
                        letter-spacing: 3.25px;
                        text-transform: uppercase;
                        padding-left: 25px;
                        padding-right: 25px;
                    }
                    .capital-bottom-box-right-svg {
                        width: 50px;
                        height: 48px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #fff;
                        margin-right: 3px;
                        img {
                            width: 20px;
                            height: 19px;
                            flex-shrink: 0;
                        }
                    }
                }
                .capital-bottom-box-right:hover {
                    .capital-bottom-box-right-btn {
                        color: #bfff00;
                    }
                    .capital-bottom-box-right-svg {
                        background: #bfff00;
                    }
                }
            }
        }
        .capital-registration {
            background: #000000;
            width: 100%;
            padding: 0 21px;
            .capital-registration-title {
                text-align: center;
                color: #fff;
                font-family: 'Inter-Bold';
                font-size: 32px;
                line-height: 65px;
                letter-spacing: -0.64px;
                padding-bottom: 15px;
                padding-top: 65px;
                margin-bottom: 35px;
                border-bottom: 1px solid rgba(201, 199, 199, 0.5);
            }

            .capital-registration-pass {
                width: 100%;
                padding-bottom: 15px;
                margin: auto;
                border-bottom: 1px solid rgba(201, 199, 199, 0.5);
                color: #fff;
                text-align: center;
                font-family: 'Inter-Light';
                font-size: 18px;
                line-height: 35px;
                letter-spacing: 5.4px;
                text-transform: uppercase;
            }

            .capital-registration-head {
                width: 100%;
                margin: auto;
                flex-shrink: 0;
                border: 1px solid #54575c;
                background: #131313;
                margin-top: 15px;
                .capital-reg-left {
                    padding-left: 20px;
                    li:nth-child(1) {
                        margin-left: auto;
                        display: flex;
                        width: 122px;
                        height: 18px;
                        background-color: #c9c7c7;
                        border-radius: 100px;
                        flex-direction: column;
                        justify-content: center;
                        flex-shrink: 0;
                        color: #131313;
                        text-align: center;
                        font-family: 'Inter-SemiBold';
                        font-size: 12px;
                        line-height: 35px;
                        letter-spacing: 2.7px;
                        text-transform: uppercase;
                        margin-top: 20px;
                        margin-right: 20px;
                    }
                    li:nth-child(2) {
                        color: #fff;
                        font-family: 'Inter-Bold';
                        font-size: 42px;
                        line-height: 50px;
                        margin-top: 14px;
                    }
                    li:nth-child(3) {
                        display: flex;
                        align-items: baseline;
                        margin-bottom: 10px;
                        .common-quan {
                            width: 7px;
                            height: 7px;
                            border-radius: 50%;
                            flex-shrink: 0;
                            background-color: #bfff00;
                            margin-right: 10px;
                        }
                        color: #c9c7c7;
                        font-family: 'Inter-Light';
                        font-size: 18px;
                        line-height: 35px;
                        letter-spacing: -0.36px;
                        margin-top: 12px;
                    }
                }
            }

            .capital-registration-middle {
                width: 100%;
                height: 1px;
                margin: auto;
                text-align: center;
                border-bottom: 1px solid rgba(201, 199, 199, 0.5);
                color: #fff;
                font-family: 'Inter-Regular';
                font-size: 18px;
                line-height: 35px;
                letter-spacing: 5.4px;
                text-transform: uppercase;
                padding-bottom: 70px;
                padding-top: 65px;
                margin-bottom: 35px;
            }

            .capital-registration-bottom {
                width: 100%;
                margin: auto;
                // display: flex;
                // justify-content: space-between;
                padding-bottom: 45px;

                .capital-registration-bottom-ul {
                    width: 100%;
                    flex-shrink: 0;
                    border: 1px solid #54575c;
                    background: #131313;
                    padding-left: 20px;
                    margin-bottom: 20px;
                    li:nth-child(1) {
                        display: flex;
                        width: 200px;
                        height: 20px;
                        border-radius: 100px;
                        align-items: center;
                        margin-left: auto;
                        margin-right: 17px;
                        margin-top: 20px;
                        flex-direction: column;
                        justify-content: center;
                        flex-shrink: 0;
                        color: #131313;
                        text-align: center;
                        font-family: 'Inter-SemiBold';
                        font-size: 12px;
                        line-height: 35px;
                        letter-spacing: 2.7px;
                        text-transform: uppercase;
                    }

                    li:nth-child(2) {
                        margin-top: 33px;
                        color: #fff;
                        font-family: 'Inter-Bold';
                        font-size: 42px;
                        line-height: 50px;
                    }

                    li {
                        display: flex;
                        align-items: baseline;
                        margin-top: 15px;

                        .common-quan {
                            width: 7px;
                            height: 7px;
                            border-radius: 50%;
                            flex-shrink: 0;
                            background-color: #bfff00;
                            margin-right: 10px;
                        }

                        .ul-wenzi {
                            width: 309px;
                            color: #c9c7c7;
                            font-family: 'Inter-Light';
                            font-size: 18px;
                            line-height: 35px;
                            letter-spacing: -0.36px;
                        }
                    }

                    li:last-child {
                        margin-top: 28px;
                    }
                }
            }

            .capital-common-right {
                height: 54px;
                background: #000;
                border: 1px solid #bfff00;
                display: flex;
                align-items: center;
                margin-left: auto;
                .capital-reg-right-title {
                    flex: 1;
                    text-align: center;
                    line-height: 54px;
                    color: #bfff00;
                    font-family: 'Inter-Bold';
                    font-size: 13px;
                    line-height: 14px;
                    letter-spacing: 3.25px;
                    text-transform: uppercase;
                }

                .capital-reg-right-img {
                    width: 56px;
                    height: 54px;
                    flex-shrink: 0;
                    background: #bfff00;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 20px;
                        height: 519px;
                        display: block;
                    }
                }
            }

            .w-230 {
                width: 230px;
            }

            .w-220 {
                width: 220px;
            }

            .capital-bottom-liner-left {
                background: linear-gradient(258deg, #5a5e64 -7.1%, #b6becb 56.27%, #565a60 104.48%);
            }

            .capital-bottom-liner-middle {
                background: linear-gradient(268deg, #d6b891 2.25%, #c79b6f 93.38%);
            }

            .capital-bottom-liner-right {
                width: 230px !important;
                background: linear-gradient(
                    259deg,
                    #cdd5db 13.32%,
                    #f6f9ff 65.06%,
                    #b8c1c9 104.42%
                );
            }
        }
        .capital-media-partner {
            border-top: 1px solid #8a8a8a;
            border-bottom: 1px solid #8a8a8a;
            background-color: #131313;
            width: 100%;
            flex-direction: column;
            .capital-media-partner-left {
                width: 100%;
                padding-top: 56px;
                padding-bottom: 59px;

                .capital-media-partner-left-content {
                    // max-width: 350px;
                    width: 100%;
                    // height: 200px;
                    margin: auto;
                    div:nth-child(1) {
                        color: #fff;
                        // width: 350px;
                        width: calc(100% - 40px);
                        font-family: 'Inter-Bold';
                        font-weight: 700;
                        font-size: 42px;
                        line-height: 50px;
                        margin: auto;
                        padding-bottom: 22px;
                        border-bottom: 1px solid #bfff00;
                    }
                    div {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-around;
                        img {
                            margin-top: 39px;
                            width: 150px;
                        }
                    }
                }
            }
            .capital-media-partner-right {
                width: 100%;
                img {
                    width: 100%;
                }
            }
        }
        // modal
        .capital-modal {
            width: 100%;
            height: 100%;
            background: #131313;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 9999;
            .capital-modal-box {
                width: 100%;
                height: 100%;
                margin: auto;
                overflow-y: scroll;
                .capital-modal-head {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 150px;
                    .capital-modal-head-left {
                        width: 100%;
                        height: 100%;
                        background: #000;
                        display: flex;
                        flex-direction: column;
                        padding-left: 23px;
                        justify-content: center;
                        position: relative;
                        .capital-modal-date {
                            color: #fff;
                            font-family: 'Inter-Bold';
                            font-size: 42px;
                            line-height: 50px;
                        }
                        .capital-modal-day {
                            color: #fff;
                            font-family: 'Inter-Light';
                            font-size: 28px;
                            line-height: 40px;
                        }
                        .capital-modal-head-right-close {
                            position: fixed;
                            top: 0;
                            right: 0;
                            display: block;
                            width: 46px;
                            height: 44px;
                            flex-shrink: 0;
                            cursor: pointer;
                            display: block;
                            object-fit: cover;
                        }
                    }
                }

                .capital-modal-content {
                    .capital-modal-content-left {
                        width: 100%;
                        height: 70px;
                        flex-shrink: 0;
                        background: #bfff00;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        clip-path: polygon(
                            0 64px,
                            0 0,
                            100% 0,
                            100% 64px,
                            calc(50% + 6px) 64px,
                            50% 70px,
                            calc(50% - 6px) 64px
                        );
                        div {
                            color: #131313;
                            font-family: 'Inter-SemiBold';
                            font-size: 18px;
                            line-height: 24px;
                            letter-spacing: -0.44px;
                            text-align: center;
                        }
                    }
                    .capital-modal-content-line {
                        width: 100%;
                        height: 7px;
                        margin-top: -6px;
                        flex-shrink: 0;
                        background: #8a8a8a;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        clip-path: polygon(
                            0 1px,
                            0 0,
                            calc(50% - 6px) 0px,
                            50% 6px,
                            calc(50% + 6px) 0px,
                            100% 0,
                            100% 1px,
                            calc(50% + 6px) 1px,
                            50% 7px,
                            calc(50% - 6px) 1px
                        );
                    }
                    .capital-modal-content-middle {
                        width: 100%;
                        height: 137px;
                        display: block;
                        object-fit: cover;
                        flex-shrink: 0;
                    }
                    .special-show {
                        padding: 10px 0;
                        text-align: center;
                        color: #131313;
                        text-align: center;
                        font-family: 'Inter-SemiBold';
                        font-size: 22px;
                        line-height: 24px;
                        letter-spacing: -0.44px;
                        background: #c9c7c7;
                    }
                    .capital-modal-content-right {
                        width: 100%;
                        height: 100%;
                        border-bottom: 1px solid #8a8a8a;
                        margin-top: -6px;
                        background: #fff;
                        display: flex;
                        // align-items: center;
                        padding: 15px 21px;
                        .capital-modal-content-right-left {
                            flex: 1;
                            margin-right: 24px;
                            .capital-modal-content-right-left-title {
                                color: #131313;
                                font-family: 'Inter-SemiBold';
                                font-size: 18px;
                                line-height: 35px;
                                letter-spacing: -0.36px;
                            }

                            .capital-modal-content-right-left-content {
                                color: #8a8a8a;
                                font-family: 'Inter-SemiBold';
                                font-size: 18px;
                                line-height: 35px;
                                letter-spacing: -0.36px;
                            }
                        }
                        img.capital-modal-content-right-left-img {
                            display: block;
                            width: 16px;
                            height: 16px;
                            cursor: pointer;
                            margin-top: 10px;
                        }
                    }

                    .capital-modal-content-view {
                        display: flex;
                        align-items: center;
                        height: 54px;
                        background: #000;
                        border: 1px solid #bfff00;
                        margin-top: 24px;
                        margin-bottom: 15px;
                        width: 90%;
                        cursor: pointer;

                        .capital-modal-content-view-img {
                            width: 56px;
                            height: 54px;
                            flex-shrink: 0;
                            background: #bfff00;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .capital-modal-content-view-title {
                            flex: 1 1;
                            text-align: center;
                            line-height: 54px;
                            color: #bfff00;
                            font-family: 'Inter-Bold';
                            font-size: 13px;
                            line-height: 14px;
                            letter-spacing: 2.25px;
                            text-transform: uppercase;
                            padding-left: 15px;
                            padding-right: 15px;
                        }
                    }
                }

                .capital-modal-bottom {
                    width: 100%;
                    height: 66px;
                    display: flex;
                    align-items: center;
                    .capital-modal-btn {
                        width: 55px;
                        height: 100%;
                        flex-shrink: 0;
                        border: 1px solid #bfff00;
                        background: #bfff00;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 26px;
                            height: 24px;
                            flex-shrink: 0;
                            cursor: pointer;
                            display: block;
                        }
                    }

                    .capital-modal-bottom-middle {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex: 1;
                        height: 100%;
                        // border: 1px solid #8A8A8A;
                        background: #000;

                        div {
                            width: 50%;
                            height: 100%;
                            color: #fff;
                            text-align: center;
                            font-family: 'Inter-Bold';
                            font-size: 20px;
                            line-height: 67px;
                        }

                        div:nth-child(1) {
                            border-right: 1px solid #8a8a8a;
                        }
                    }
                }
            }
        }

        .capital-modal-partner {
            width: 100%;
            height: 100%;
            background: #8a8a8a66;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 9999;
            .capital-modal-partner-detail {
                width: 348px;
                height: 100%;
                border: 1px solid #8a8a8a;
                margin: auto;
                margin-top: 23px;
                overflow-y: scroll;
                position: relative;
                .capital-modal-partner-close {
                    top: 29px;
                    right: 29px;
                    position: absolute;
                    cursor: pointer;
                }
                .capital-modal-partner-img {
                    width: 348px;
                    height: 196px;
                }
                .capital-modal-partner-content {
                    padding: 22px;
                    background: #000;
                    .capital-modal-partner-title {
                        font-family: 'Inter-Bold';
                        font-size: 34px;
                        font-weight: 700;
                        line-height: 40px;
                        letter-spacing: 0em;
                        text-align: center;
                        color: #fff;
                    }
                    .capital-modal-partner-subtitle {
                        font-family: 'Inter-Bold';
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 35px;
                        letter-spacing: -0.02em;
                        text-align: center;
                        color: #8a8a8a;
                    }
                    .capital-modal-partner-desc {
                        margin-top: 38px;
                        font-family: 'Inter-Bold';
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 35px;
                        letter-spacing: -0.02em;
                        text-align: left;
                        color: #c9c7c7;
                        margin-top: 25px;
                        a {
                            font-weight: 400;
                            color: #bfff00;
                        }
                    }
                }
                .capital-modal-partne-button {
                    margin: auto;
                    display: flex;
                    align-items: center;
                    direction: rtl;
                    cursor: pointer;
                    .capital-modal-partner-learn {
                        height: 54px;
                        background: #000;
                        border: 1px solid #bfff00;
                        display: flex;
                        align-items: center;
                        .capital-reg-right-title {
                            flex: 1;
                            text-align: center;
                            line-height: 54px;
                            color: #bfff00;
                            font-family: 'Inter-Bold';
                            font-size: 13px;
                            line-height: 14px;
                            letter-spacing: 3.25px;
                            text-transform: uppercase;
                            padding-left: 25px;
                            padding-right: 25px;
                        }
                        .capital-reg-right-img {
                            width: 56px;
                            height: 54px;
                            flex-shrink: 0;
                            background: #bfff00;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: 20px;
                                height: 519px;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

.capital-special-color {
    color: #00de8e !important;
}
