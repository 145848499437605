@media (min-width: 1440px) {
    .subscribeWrap {
        border-top: 1px solid #8a8a8a;
        border-bottom: 1px solid #8a8a8a;
        background: #bfff00;
        width: 100vw;
        height: 103px;
        display: flex;
        justify-content: center;
        align-items: center;
        .labelText {
            color: #131313;
            font-size: 18px;
            font-family: 'Inter-SemiBold';
            font-weight: 600;
            line-height: 35px;
            letter-spacing: -0.36px;
            margin-right: 60px;
        }
        .inputWrap {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 538px;
            height: 54px;
            input {
                height: 54px;
                border: none;
                background-color: transparent;
                outline: none;
                color: #131313;
                font-size: 18px;
                font-family: 'Inter-SemiBold';
                font-weight: 600;
                line-height: 35px;
                letter-spacing: -0.36px;
            }
            ::-webkit-input-placeholder {
                /*Webkit browsers*/
                color: #131313;
                /* Form_placeholder */
                font-size: 18px;
                font-family: 'Inter-SemiBold';
                font-weight: 600;
                line-height: 35px;
                letter-spacing: -0.36px;
            }
            :-moz-placeholder {
                /*Mozilla Firefox 4 to 8*/
                color: #131313;
                /* Form_placeholder */
                font-size: 18px;
                font-family: 'Inter-SemiBold';
                font-weight: 600;
                line-height: 35px;
                letter-spacing: -0.36px;
            }
            ::moz-placeholder {
                /*Mozilla Firefox 19+*/
                color: #131313;
                /* Form_placeholder */
                font-size: 18px;
                font-family: 'Inter-SemiBold';
                font-weight: 600;
                line-height: 35px;
                letter-spacing: -0.36px;
            }
            :-ms-input-placeholder {
                /*Internet Explorer 10+*/
                color: #131313;
                /* Form_placeholder */
                font-size: 18px;
                font-family: 'Inter-SemiBold';
                font-weight: 600;
                line-height: 35px;
                letter-spacing: -0.36px;
            }
            /* Input container */
            .input-container {
                position: relative;
                width: 100%;
                height: 54px;
            }
            /* Input field */
            .input-field {
                display: block;
                width: 100%;
                font-size: 16px;
                border: none;
                border-bottom: 1px solid black;
                outline: none;
                background-color: transparent;
                height: 54px;
            }
            /* Input label */
            .input-label {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 16px;
                color: rgba(204, 204, 204, 0);
                pointer-events: none;
                transition: all 0.3s ease;
            }
            /* Input highlight */
            .input-highlight {
                position: absolute;
                bottom: 0;
                left: 0;
                height: 1px;
                width: 0;
                background-color: #bfff00;
                transition: all 0.3s ease;
            }
            /* Input field:focus styles */
            .input-field:focus + .input-label {
                top: -20px;
                color: #131313;
                font-size: 14px;
                font-family: 'Inter-SemiBold';
                font-weight: 600;
                letter-spacing: -0.28px;
            }
            .input-field:focus + .input-label + .input-highlight {
                width: 100%;
            }
            .arrowBtn {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #000;
                background: #000;
                width: 54px;
                height: 54px;
                cursor: pointer;
                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}
@media (min-width: 1024px) and (max-width: 1439px) {
    .subscribeWrap {
        border-top: 1px solid #8a8a8a;
        border-bottom: 1px solid #8a8a8a;
        background: #bfff00;
        width: 100vw;
        height: 103px;
        display: flex;
        justify-content: center;
        align-items: center;
        .labelText {
            color: #131313;
            font-size: 18px;
            font-family: 'Inter-SemiBold';
            font-weight: 600;
            line-height: 35px;
            letter-spacing: -0.36px;
            margin-right: 60px;
        }
        .inputWrap {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 538px;
            height: 54px;
            input {
                height: 54px;
                border: none;
                background-color: transparent;
                outline: none;
                color: #131313;
                font-size: 18px;
                font-family: 'Inter-SemiBold';
                font-weight: 600;
                line-height: 35px;
                letter-spacing: -0.36px;
            }
            ::-webkit-input-placeholder {
                /*Webkit browsers*/
                color: #131313;
                /* Form_placeholder */
                font-size: 18px;
                font-family: 'Inter-SemiBold';
                font-weight: 600;
                line-height: 35px;
                letter-spacing: -0.36px;
            }
            :-moz-placeholder {
                /*Mozilla Firefox 4 to 8*/
                color: #131313;
                /* Form_placeholder */
                font-size: 18px;
                font-family: 'Inter-SemiBold';
                font-weight: 600;
                line-height: 35px;
                letter-spacing: -0.36px;
            }
            ::moz-placeholder {
                /*Mozilla Firefox 19+*/
                color: #131313;
                /* Form_placeholder */
                font-size: 18px;
                font-family: 'Inter-SemiBold';
                font-weight: 600;
                line-height: 35px;
                letter-spacing: -0.36px;
            }
            :-ms-input-placeholder {
                /*Internet Explorer 10+*/
                color: #131313;
                /* Form_placeholder */
                font-size: 18px;
                font-family: 'Inter-SemiBold';
                font-weight: 600;
                line-height: 35px;
                letter-spacing: -0.36px;
            }
            /* Input container */
            .input-container {
                position: relative;
                width: 100%;
                height: 54px;
            }
            /* Input field */
            .input-field {
                display: block;
                width: 100%;
                font-size: 16px;
                border: none;
                border-bottom: 1px solid black;
                outline: none;
                background-color: transparent;
                height: 54px;
            }
            /* Input label */
            .input-label {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 16px;
                color: rgba(204, 204, 204, 0);
                pointer-events: none;
                transition: all 0.3s ease;
            }
            /* Input highlight */
            .input-highlight {
                position: absolute;
                bottom: 0;
                left: 0;
                height: 1px;
                width: 0;
                background-color: #bfff00;
                transition: all 0.3s ease;
            }
            /* Input field:focus styles */
            .input-field:focus + .input-label {
                top: -20px;
                color: #131313;
                font-size: 14px;
                font-family: 'Inter-SemiBold';
                font-weight: 600;
                letter-spacing: -0.28px;
            }
            .input-field:focus + .input-label + .input-highlight {
                width: 100%;
            }
            .arrowBtn {
                display: flex;
                justify-content: center;
                align-items: center;
                background: #bfff00;
                width: 54px;
                height: 54px;
                cursor: pointer;
                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}
@media (max-width: 1023px) {
    .subscribeWrap {
        border-bottom: 1px solid #8a8a8a;
        background: #bfff00;
        width: 100vw;
        height: 180px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 20px;
        .labelText {
            color: #131313;
            text-align: center;
            font-size: 18px;
            font-family: 'Inter-SemiBold';
            font-weight: 600;
            line-height: 35px;
            letter-spacing: -0.36px;
            margin-bottom: 20px;
        }
        .inputWrap {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            height: 54px;
            input {
                height: 54px;
                background-color: transparent;
                outline: none;
                color: #131313;
                font-size: 18px;
                font-family: 'Inter-SemiBold';
                font-weight: 600;
                line-height: 35px;
                letter-spacing: -0.36px;
                border: none;
            }
            ::-webkit-input-placeholder {
                /*Webkit browsers*/
                color: #131313;
                /* Form_placeholder */
                font-size: 18px;
                font-family: 'Inter-SemiBold';
                font-weight: 600;
                line-height: 35px;
                letter-spacing: -0.36px;
            }
            :-moz-placeholder {
                /*Mozilla Firefox 4 to 8*/
                color: #131313;
                /* Form_placeholder */
                font-size: 18px;
                font-family: 'Inter-SemiBold';
                font-weight: 600;
                line-height: 35px;
                letter-spacing: -0.36px;
            }
            ::moz-placeholder {
                /*Mozilla Firefox 19+*/
                color: #131313;
                /* Form_placeholder */
                font-size: 18px;
                font-family: 'Inter-SemiBold';
                font-weight: 600;
                line-height: 35px;
                letter-spacing: -0.36px;
            }
            :-ms-input-placeholder {
                /*Internet Explorer 10+*/
                color: #131313;
                /* Form_placeholder */
                font-size: 18px;
                font-family: 'Inter-SemiBold';
                font-weight: 600;
                line-height: 35px;
                letter-spacing: -0.36px;
            }
            /* Input container */
            .input-container {
                position: relative;
                width: 100%;
                height: 54px;
            }
            /* Input field */
            .input-field {
                display: block;
                width: 100%;
                font-size: 16px;
                outline: none;
                background-color: transparent;
                height: 54px;
            }
            /* Input label */
            .input-label {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 16px;
                color: rgba(204, 204, 204, 0);
                pointer-events: none;
                transition: all 0.3s ease;
            }
            /* Input highlight */
            .input-highlight {
                position: absolute;
                bottom: 0;
                left: 0;
                height: 1px;
                width: 0;
                background-color: #bfff00;
                transition: all 0.3s ease;
                z-index: 1;
            }
            /* Input highlight */
            .input-highNone {
                position: absolute;
                bottom: 0;
                left: 0;
                height: 1px;
                width: 100%;
                background-color: #ccc;
                transition: all 0.3s ease;
            }
            /* Input field:focus styles */
            .input-field:focus + .input-label {
                top: -20px;
                color: #131313;
                font-size: 14px;
                font-family: 'Inter-SemiBold';
                font-weight: 600;
                letter-spacing: -0.28px;
            }
            .input-field:focus + .input-label + .input-highlight {
                width: 100%;
            }
            .arrowBtn {
                display: flex;
                justify-content: center;
                align-items: center;
                background: #bfff00;
                width: 54px;
                height: 54px;
                cursor: pointer;
                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}
